import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getSupplierDropDownAction } from '../../redux/actions/customerAction'
import { dateFormate } from '../../helper/utils'
import { getPurchaseByIdAction } from '../../redux/actions/purchaseAction'

const ViewPurchase = ({ show, onHide, viewId }) => {

    const dispatch = useDispatch()

    const [purchase, setPurchase] = useState({
        invoiceDate: '',
        customer: '',
        invoiceNo: '',
        narration: '',
        productAmount: '',
        discountAmount: '',
        totalAmount: '',
    })
    const [productDetails, setProductDetails] = useState([
        {
            productName: '',
            quantity: '',
            rate: '',
            amount: '',
            gst: 0,
            discount: 0,
            finalAmount: 0,
            description: '',
        }
    ])

    const getPurchaseByIdData = useSelector((state) => state.get_Purchase_By_Id.getPurchaseById)
    const supplierData = useSelector((state) => state.get_Supplier_Drop_Down.getSupplierDropDown.data)

    useEffect(() => {
        dispatch(getSupplierDropDownAction())
    }, [])

    useEffect(() => {
        if (viewId) {
            dispatch(getPurchaseByIdAction(viewId))
        }
    }, [viewId])

    useEffect(() => {
        if (getPurchaseByIdData && getPurchaseByIdData.data && supplierData && viewId) {
            setPurchase({
                invoiceDate: new Date(getPurchaseByIdData.data.invoiceDate),
                customer: getPurchaseByIdData.data.customer ? selectValue(getPurchaseByIdData.data.customer, "supplier") : '',
                invoiceNo: getPurchaseByIdData.data.invoiceNo,
                narration: getPurchaseByIdData.data.narration,
                productAmount: getPurchaseByIdData.data.productAmount,
                discountAmount: getPurchaseByIdData.data.discountAmount,
                totalAmount: getPurchaseByIdData.data.totalAmount,
            })
            setProductDetails(getPurchaseByIdData.data.purchaseItems.map((item) => {
                return {
                    productName: item.productName,
                    quantity: item.quantity,
                    rate: item.rate,
                    amount: item.amount,
                    gst: item.gst,
                    discount: item.discount,
                    finalAmount: item.finalAmount,
                    description: item.description,
                }
            }))
        }
    }, [getPurchaseByIdData])

    const selectValue = (value, name) => {
        if (name === "supplier") {
            return supplierData.find((ele) => ele._id === value)?.accountName
        }
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
            size="xl"
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title>Purchase</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4 className='text-center'>SHRADDHA FIRE AND SAFETY SERVICES</h4>
                <h6 className='text-center'>119,ZENON,NEAR OPERA HOUSE,OPP,UNIQUE HOSPITAL,OFF.RING ROAD,UDHNA,SURAT</h6>
                <hr></hr>
                <Row>
                    <Col lg={6}>
                        <div className="mx-0 mt-3">
                            <table className='table viewer-product-modal'>
                                <tr>
                                    <td>Invoice Date</td>
                                    <td>{dateFormate(purchase.invoiceDate, "Date")}</td>
                                </tr>
                                <tr>
                                    <td>Invoice No</td>
                                    <td>{purchase?.invoiceNo}</td>

                                </tr>
                            </table>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="mx-0 mt-3">
                            <table className='table viewer-product-modal'>
                                <tr>
                                    <td>Supplier</td>
                                    <td>{purchase.customer}</td>
                                </tr>
                                <tr>
                                    <td>Narration</td>
                                    <td>{purchase?.narration}</td>
                                </tr>
                            </table>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} className="">
                        <h2 className="Steps-header">Product Details</h2>
                    </Col>
                </Row>
                <table style={{ width: "100%" }}>
                    <tr>
                        <th className='vertical-top' style={{ width: "3%" }}>Sr</th>
                        <th className='vertical-top' style={{ width: "25%" }}>Product Name</th>
                        <th className='vertical-top' style={{ width: "10%" }}>Quantity</th>
                        <th className='vertical-top' style={{ width: "10%" }}>Rate</th>
                        <th className='vertical-top' style={{ width: "5%" }}>GST</th>
                        <th className='vertical-top' style={{ width: "10%" }}>Discount (%)</th>
                        <th className='vertical-top' style={{ width: "10%" }}>Amount</th>
                        <th className='vertical-top' style={{ width: "25%" }}>Description</th>
                    </tr>
                    {productDetails.map((item, i) =>
                        <tr key={i}>
                            <td className='vertical-top' style={{ width: "3%" }}>{i + 1}</td>
                            <td className='vertical-top' style={{ width: "25%" }}>{item.productName}</td>
                            <td className='vertical-top' style={{ width: "10%" }}>{item.quantity}</td>
                            <td className='vertical-top' style={{ width: "10%" }}>{item.rate}</td>
                            <td className='vertical-top' style={{ width: "5%" }}>{Number(item?.gst)?.toFixed(2)}</td>
                            <td className='vertical-top' style={{ width: "10%" }}>{Number(item?.discount)?.toFixed(2)}</td>
                            <td className='vertical-top' style={{ width: "10%" }}>{Number(item?.finalAmount)?.toFixed(2)}</td>
                            <td className='vertical-top' style={{ width: "25%" }}>{item.description}</td>
                        </tr>
                    )}
                </table>
                <Row>
                    <Col lg={6}>
                    </Col>
                    <Col lg={6}>
                        <div className="mx-0 mt-3">
                            <table className='table viewer-product-modal'>
                                {/* <tr>
                                    <td>Product Amount</td>
                                    <td>{purchase?.productAmount}</td>
                                </tr>
                                <tr>
                                    <td>Discount Amount</td>
                                    <td>{purchase?.discountAmount}</td>
                                </tr> */}
                                <tr>
                                    <td>Total Amount</td>
                                    <td>{purchase?.totalAmount}</td>
                                </tr>
                            </table>
                        </div>
                    </Col>
                    {/* <Col lg={3}>
                        <div className="mx-0 mt-3">
                            <div className="users-label bill-type d-flex">
                                <label className='d-block me-3'>Product Amount :</label><span>{purchase.productAmount}</span>
                            </div>
                        </div>
                    </Col>
                    <Col lg={3}>
                        <div className="mx-0 mt-3">
                            <div className="users-label bill-type d-flex">
                                <label className='d-block me-3'>Discount Amount :</label><span>{purchase.discountAmount}</span>
                            </div>
                        </div>
                    </Col>
                    <Col lg={3}>
                        <div className="mx-0 mt-3">
                            <div className="users-label bill-type d-flex">
                                <label className='d-block me-3'>Total Amount :</label><span>{purchase.totalAmount}</span>
                            </div>
                        </div>
                    </Col> */}
                </Row>
            </Modal.Body>
            {/* <Modal.Footer>
                <button className='cancel-button' onClick={onHide}>Cancel</button>
            </Modal.Footer> */}

        </Modal >
    )
}

export default ViewPurchase
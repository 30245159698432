import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getAllProjectAction, getProjectByIdAction } from '../../redux/actions/projectAction'
import { getAllCustomerAction } from '../../redux/actions/customerAction'
import { getAllContractorAction } from '../../redux/actions/contractorAction'
import { getProjectManagerAction } from '../../redux/actions/userAction'
import { dateFormate } from '../../helper/utils'
import { getSaleByIdAction } from '../../redux/actions/salesAction'

const ViewSale = ({ show, onHide, viewId }) => {

    const dispatch = useDispatch()

    const [sale, setSale] = useState({
        invoiceDate: '',
        customer: '',
        project: '',
        invoiceNo: '',
        transpoter: '',
        purchaseRequest: '',
        refby: '',
        narration: '',
        termAndConditions: '',
        productAmount: '',
        totalAmount: '',
    })
    const [productDetails, setProductDetails] = useState([
        {
            productName: '',
            quantity: '',
            rate: '',
            gst: '',
            discount: '',
            amount: '',
            finalAmount: '',
            description: '',
        }
    ])

    const getSaleByIdData = useSelector((state) => state.get_Sale_By_Id.getSaleById)
    const customerData = useSelector((state) => state.get_All_Customer.getAllCustomer.data)
    const getAllProject = useSelector((state) => state.get_All_Project.getAllProject.data)

    useEffect(() => {
        let body = '';
        dispatch(getAllProjectAction(body))
        dispatch(getAllCustomerAction())
    }, [])

    useEffect(() => {
        if (viewId) {
            dispatch(getSaleByIdAction(viewId))
        }
    }, [viewId])

    useEffect(() => {
        if (getSaleByIdData && getSaleByIdData.data && getAllProject && customerData && viewId) {
            setSale({
                invoiceDate: new Date(getSaleByIdData.data.invoiceDate),
                customer: getSaleByIdData.data.customer ? selectValue(getSaleByIdData.data.customer, "customer") : '',
                project: getSaleByIdData.data.project ? selectValue(getSaleByIdData.data.project, "project") : '',
                invoiceNo: getSaleByIdData.data.invoiceNo,
                transpoter: getSaleByIdData.data.transpoter,
                purchaseRequest: getSaleByIdData.data.purchaseRequest,
                refby: getSaleByIdData.data.refby,
                narration: getSaleByIdData.data.narration,
                termAndConditions: getSaleByIdData.data.termAndConditions,
                productAmount: getSaleByIdData.data.productAmount,
                totalAmount: getSaleByIdData.data.totalAmount,
            })
            setProductDetails(getSaleByIdData.data.purchaseItems.map((item) => {
                return {
                    productName: item.productName,
                    quantity: item.quantity,
                    rate: item.rate,
                    gst: item?.gst,
                    discount: item?.discount,
                    amount: item.amount,
                    finalAmount: item?.finalAmount ?? item?.amount ?? 0,
                    description: item.description,
                }
            }))
        }
    }, [getSaleByIdData, customerData, getAllProject])

    const selectValue = (value, name) => {
        if (name === "customer") {
            return customerData.find((ele) => ele._id === value)?.accountName
        } else if (name === "project") {
            return getAllProject.find((ele) => ele._id === value)?.partyName
        }
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
            size="xl"
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title>Sale</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4 className='text-center'>SHRADDHA FIRE AND SAFETY SERVICES</h4>
                <h6 className='text-center'>119,ZENON,NEAR OPERA HOUSE,OPP,UNIQUE HOSPITAL,OFF.RING ROAD,UDHNA,SURAT</h6>
                <hr></hr>
                <Row>
                    <Col lg={6}>
                        <div className="mx-0 mt-3">
                            <table className='table viewer-product-modal'>
                                <tr>
                                    <td>Invoice Date</td>
                                    <td>{dateFormate(sale.invoiceDate, "Date")}</td>
                                </tr>
                                <tr>
                                    <td>Customer</td>
                                    <td>{sale.customer}</td>
                                </tr>
                                <tr>
                                    <td>Project</td>
                                    <td>{sale.project}</td>
                                </tr>
                                <tr>
                                    <td>Transpoter</td>
                                    <td>{sale?.transpoter}</td>
                                </tr>
                            </table>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="mx-0 mt-3">
                            <table className='table viewer-product-modal'>
                                <tr>
                                    <td>Invoice No</td>
                                    <td>{sale?.invoiceNo}</td>
                                </tr>
                                <tr>
                                    <td>Purchase Request</td>
                                    <td>{sale?.purchaseRequest}</td>
                                </tr>
                                <tr>
                                    <td>RefBy</td>
                                    <td>{sale?.refby}</td>
                                </tr>
                               
                            </table>
                        </div>
                    </Col>
                    <Col lg={12}>
                        <div className="mx-0 mt-2">
                            <table className='table viewer-product-modal'>
                                <tr>
                                    <td>Narration</td>
                                    <td>{sale?.narration}</td>
                                </tr>
                            </table>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} className="">
                        <h2 className="Steps-header">Product Details</h2>
                    </Col>
                </Row>
                <table style={{ width: "100%" }}>
                    <tr>
                        <th className='vertical-top' style={{ width: "3%" }}>Sr</th>
                        <th className='vertical-top' style={{ width: "30%" }}>Product Name</th>
                        <th className='vertical-top' style={{ width: "8%" }}>Quantity</th>
                        <th className='vertical-top' style={{ width: "8%" }}>Rate</th>
                        <th className='vertical-top' style={{ width: "6%" }}>GST %</th>
                        <th className='vertical-top' style={{ width: "6%" }}>Disc %</th>
                        <th className='vertical-top' style={{ width: "10%" }}>Amount</th>
                        <th className='vertical-top' style={{ width: "25%" }}>Description</th>
                    </tr>
                    {productDetails.map((item, i) =>
                        <tr key={i}>
                            <td className='vertical-top' style={{ width: "3%" }}>{i + 1}</td>
                            <td className='vertical-top' style={{ width: "30%" }}>{item.productName}</td>
                            <td className='vertical-top' style={{ width: "8%" }}>{item.quantity}</td>
                            <td className='vertical-top' style={{ width: "8%" }}>{item.rate}</td>
                            <td className='vertical-top' style={{ width: "5%" }}>{Number(item?.gst ?? 0)?.toFixed(2)}</td>
                            <td className='vertical-top' style={{ width: "5%" }}>{Number(item?.discount ?? 0)?.toFixed(2)}</td>
                            <td className='vertical-top' style={{ width: "10%" }}>{Number(item?.finalAmount)?.toFixed(2)}</td>
                            <td className='vertical-top' style={{ width: "25%" }}>{item.description}</td>
                        </tr>
                    )}
                </table>
                <Row>

                    <Col lg={6}>
                        <div className="mx-0 mt-3">
                            <table className='table viewer-product-modal'>
                                <tr>
                                    <td>Terms And Conditions</td>
                                    <td>{sale?.termAndConditions}</td>
                                </tr>
                            </table>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="mx-0 mt-3">
                            <table className='table viewer-product-modal'>
                                <tr>
                                    <td>Product Amount</td>
                                    <td>{sale?.productAmount}</td>
                                </tr>
                                <tr>
                                    <td>Total Amount</td>
                                    <td>{Number(sale?.totalAmount)?.toFixed(2)}</td>
                                </tr>

                            </table>
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
            {/* <Modal.Footer>
                <button className='cancel-button' onClick={onHide}>Cancel</button>
            </Modal.Footer> */}

        </Modal >
    )
}

export default ViewSale
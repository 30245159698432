import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import Datepickericon from "../../assets/image/date-picker-icon.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import img from "../../assets/image/select.png";
import { toast } from "react-toastify";
import Plusicon from "../../assets/image/plus.png";
import Minusicon from "../../assets/image/minus.png";
import { getSupplierDropDownAction } from "../../redux/actions/customerAction";
import { addPurchaseAction, editPurchaseAction, getPurchaseByIdAction } from "../../redux/actions/purchaseAction";
import { getProductDropDownAction } from "../../redux/actions/productAction";
import { EDIT_PURCHASE } from "../../redux/type"
import { Col, Row } from "react-bootstrap";
import Select from 'react-select'

const AddPurchase = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search)
    const purchaseId = queryParams.get("purchaseId")
    const toastSuccess = () => toast.success("Purchase added successfully");
    const toastUpdate = () => toast.success("Purchase update successfully");
    const [rotateArrowProduct, setrotateArrowProduct] = useState('');
    const [formErrors, setFormErrors] = useState({});
    const [purchaseForm, setPurchaseForm] = useState({
        invoiceDate: "",
        customer: "",
        invoiceNo: "",
        narration: "",
        productAmount: 0,
        discountAmount: 0,
        totalAmount: 0,
        gst: 18,
    });
    const [editId, setEditId] = useState()
    const [supplierOption, setSupplierOption] = useState([])
    const [productOption, setProductOption] = useState([])
    const [rotateArrow, setRotateArrow] = useState('');
    const productData = useSelector((state) => state.get_Product_Drop_Down.getProductDropDown.data)
    const purchaseData = useSelector((state) => state.get_Purchase_By_Id.getPurchaseById.data)
    const editPurchaseData = useSelector((state) => state.edit_Purchase.editPurchase)
    const supplierData = useSelector((state) => state.get_Supplier_Drop_Down.getSupplierDropDown.data)

    const [productDetails, setProductDetails] = useState([
        {
            productCode: "",
            productName: "",
            description: "",
            quantity: 0,
            rate: 0,
            amount: 0,
            showPrice: 0,
            showQty: 0,
            gst: 18,
            discount: 0,
            finalAmount: 0
        },
    ]);

    useEffect(() => {
        dispatch(getSupplierDropDownAction())
        dispatch(getProductDropDownAction())
    }, [])

    useEffect(() => {
        if (purchaseId) {
            setEditId(purchaseId)
            dispatch(getPurchaseByIdAction(purchaseId))
        }
    }, [purchaseId])


    useEffect(() => {
        if (supplierData) {
            let temp = supplierData.sort((a, b) => a.accountName.localeCompare(b.accountName, undefined, { case: 'upper' }))
            setSupplierOption(temp.map((ele) => {
                return {
                    value: ele._id,
                    label: ele.accountName
                }
            }))
        }
    }, [supplierData])

    useEffect(() => {
        if (productData) {
            let temp = productData.sort((a, b) => a.productName.localeCompare(b.productName, undefined, { case: 'upper' }))
            setProductOption(temp.map((ele) => {
                return {
                    value: ele.productCode,
                    label: ele.productCode ? `${ele.productName}` : ele.productName
                }
            }))
        }
    }, [productData])

    useEffect(() => {
        if (editPurchaseData && editPurchaseData.data) {
            dispatch({
                type: EDIT_PURCHASE,
                payload: []
            })
            navigate('/purchase')
        }
    }, [editPurchaseData])

    useEffect(() => {
        if (purchaseData && purchaseId) {
            setPurchaseForm({
                invoiceDate: new Date(purchaseData.invoiceDate),
                customer: purchaseData.customer,
                invoiceNo: purchaseData.invoiceNo,
                narration: purchaseData.narration,
                productAmount: purchaseData.productAmonut,
                discountAmount: purchaseData.discountAmount,
                totalAmount: purchaseData.totalAmount,
                gst: purchaseData.gst ?? 0,
            })
            setProductDetails(purchaseData.purchaseItems.map((item) => {
                return {
                    productCode: item.productCode,
                    productName: item.productName,
                    description: item.description,
                    quantity: item.quantity,
                    rate: item.rate,
                    amount: item.amount,
                    showPrice: productData?.find((items) => items.productCode === item.productCode)?.retailSalePrice,
                    showQty: productData?.find((items) => items.productCode === item.productCode)?.stock,
                    gst: item?.gst ?? 0,
                    discount: item?.discount ?? 0,
                    finalAmount: item?.finalAmount ?? 0
                }
            }))
        }
    }, [purchaseData])

    const handleChange = (e, name) => {
        setFormErrors({ ...formErrors, [name]: '' })
        let re = /^[0-9\b]+$/;
        let regexwithdecimal = /^(?:\d*\.\d{0,2}|\d+)$/;
        if (name === "discountAmount") {
            if (e.target.value === "" || regexwithdecimal.test(e.target.value)) {
                let discAmt = Number(e.target.value) ?? 0;
                let disc = Number(purchaseForm.productAmount) - discAmt
                setPurchaseForm({ ...purchaseForm, totalAmount: disc, discountAmount: e.target.value })
            }
        } else if (name === "customer") {
            setPurchaseForm({
                ...purchaseForm,
                [name]: e.value,
            });
        } else if (name === "total") {
            setPurchaseForm({
                ...purchaseForm,
                [name]: e.value,
            });
        } else {
            setPurchaseForm({
                ...purchaseForm,
                [name]: e.target.value,
            });
        }
    };

    const Submit = () => {
        if (validation()) {
            return
        }
        let body = {
            ...purchaseForm,
            purchaseItems: productDetails.map((item) => {
                return {
                    productCode: item.productCode,
                    productName: productData.find((el) => el.productCode === item.productCode).productName,
                    // productName: item.productName,
                    description: item.description,
                    quantity: item.quantity,
                    rate: item.rate,
                    amount: item.amount,
                    gst: item.gst,
                    discount: item.discount,
                    finalAmount: item.finalAmount
                }
            })
        }
        dispatch(addPurchaseAction(body))
        toastSuccess()
        navigate('/purchase')

    };

    const Edit = () => {
        if (validation()) {
            return
        }
        let body = {
            ...purchaseForm,
            id: editId,
            purchaseItems: productDetails.map((item) => {
                return {
                    productCode: item.productCode,
                    productName: productData.find((el) => el.productCode === item.productCode)?.productName,
                    // productName: item.productName,
                    description: item.description,
                    quantity: item.quantity,
                    rate: item.rate,
                    amount: item.amount,
                    gst: item.gst,
                    discount: item.discount,
                    finalAmount: item.finalAmount
                }
            })
        }
        dispatch(editPurchaseAction(body))
        toastUpdate(true)
    };

    const Blur = () => {
        setRotateArrow('')
    }

    const Click = (name) => {
        if (rotateArrow === name) {
            setRotateArrow('')
        } else {
            setRotateArrow(name)
        }
    }

    const addData = () => {
        let rotate = [...rotateArrowProduct];
        let val = [...productDetails];
        val.push({
            productCode: "",
            productName: "",
            description: "",
            quantity: 0,
            rate: 0,
            amount: 0,
            gst: 18,
            discount: 0,
            finalAmount: 0
        });
        rotate.push({
            productCode: false,
        })
        setrotateArrowProduct(rotate)
        setProductDetails(val);
    };

    const Remove = (i) => {
        if (productDetails.length > 1) {
            let rotate = [...rotateArrowProduct];
            let val = [...productDetails];
            val.splice(i, 1);
            rotate.splice(i, 1);
            setProductDetails(val);
            setrotateArrowProduct(rotate)
        }
    };

    const decimalNumberWithMaxVal = (value) => {
        const regexwithdecimal = /^(?:\d*\.\d{0,2}|\d+)$/;
        if (regexwithdecimal.test(value) && +value <= 100) {
            return true
        }
        return false;
    }

    const handleChangeData = (e, i, name) => {
        setFormErrors({ ...formErrors, [`${name}${i + 1}`]: '' })
        let val = [...productDetails];
        let regex = /^[0-9\b]+$/;
        let regexwithdecimal = /^(?:\d*\.\d{0,2}|\d+)$/;
        if (name === "description") {
            val[i].description = e.target.value;
            setProductDetails(val);
        } else if (name === "rate") {
            if (e.target.value === "" || regexwithdecimal.test(e.target.value)) {
                const currentRate = e.target.value ? Number(e.target.value) : 0
                let tempQty = val[i].quantity ? Number(val[i].quantity) : 0
                let tempGST = val[i].gst ? Number(val[i].gst) : 0
                let tempDic = val[i].discount ? Number(val[i].discount) : 0
                let amount = tempQty * currentRate
                let finalAmount = amount - (amount * tempDic / 100);
                finalAmount = finalAmount + (finalAmount * tempGST / 100);
                val[i].rate = currentRate;
                val[i].amount = amount;
                val[i].finalAmount = finalAmount;
                setProductDetails(val);
            }
        } else if (name === "quantity") {
            if (e.target.value === "" || regexwithdecimal.test(e.target.value)) {
                const currentQty = e.target.value ? Number(e.target.value) : 0
                let tempRate = val[i].rate ? Number(val[i].rate) : 0
                let tempGST = val[i].gst ? Number(val[i].gst) : 0
                let tempDic = val[i].discount ? Number(val[i].discount) : 0
                let amount = currentQty * tempRate
                let finalAmount = amount - (amount * tempDic / 100);
                finalAmount = finalAmount + (finalAmount * tempGST / 100);
                val[i].quantity = currentQty;
                val[i].amount = amount;
                val[i].finalAmount = finalAmount;
                setProductDetails(val);
            }
        } else if (name === "productCode") {
            if (productDetails.find((ele) => ele.productCode === e.value)) {
                toast.error("You already selected this Product!");
                val[i].productCode = '';
                val[i].productName = ''
                val[i].showPrice = ''
                val[i].showQty = ''
                setProductDetails(val);
            } else {
                val[i].productCode = e.value;
                val[i].productName = e.label
                val[i].showPrice = productData.find((item) => item.productCode === e.value)?.retailSalePrice
                val[i].showQty = productData.find((item) => item.productCode === e.value)?.stock
                setProductDetails(val);
            }
        } else if (name === "gst") {
            if (e.target.value === "" || decimalNumberWithMaxVal(e.target.value)) {
                const currentGST = e.target.value ? Number(e.target.value) : 0
                let tempQty = val[i].quantity ? Number(val[i].quantity) : 0
                let tempRate = val[i].rate ? Number(val[i].rate) : 0
                let tempDic = val[i].discount ? Number(val[i].discount) : 0
                let finalAmount = tempQty * tempRate
                finalAmount = finalAmount - (finalAmount * tempDic / 100);
                finalAmount = finalAmount + (finalAmount * currentGST / 100);
                val[i].gst = currentGST;
                val[i].finalAmount = finalAmount;
                setProductDetails(val);
            }
        } else if (name === "discount") {
            if (e.target.value === "" || decimalNumberWithMaxVal(e.target.value)) {
                const currentDisc = e.target.value ? Number(e.target.value) : 0
                let tempQty = val[i].quantity ? Number(val[i].quantity) : 0
                let tempRate = val[i].rate ? Number(val[i].rate) : 0
                let tempGST = val[i].gst ? Number(val[i].gst) : 0
                let finalAmount = tempQty * tempRate
                val[i].discount = currentDisc;
                finalAmount = finalAmount - (finalAmount * currentDisc / 100);
                finalAmount = finalAmount + (finalAmount * tempGST / 100);
                val[i].finalAmount = finalAmount;
                setProductDetails(val);
            }
        }
    };

    useEffect(() => {
        if (productDetails) {
            // let temp = 0;
            // let totalAmt = 0;
            // productDetails.map((item) => temp += item.amount)
            // totalAmt = temp - purchaseForm.discountAmount
            const productAmount = productDetails?.reduce((accum, item) => accum + (item?.rate * item?.quantity), 0)
            const totalAmount = productDetails?.reduce((accum, item) => accum + item?.finalAmount, 0)
            setPurchaseForm({ ...purchaseForm, productAmount, totalAmount })
        }
    }, [productDetails])


    const validation = () => {
        let flag = false;
        const error = {};
        if (!purchaseForm.invoiceDate) {
            error.invoiceDate = "Please select invoice date";
            flag = true;
        }
        // if(purchaseForm.totalAmount){
        //     purchaseForm.totalAmount = purchaseForm.totalAmount + (purchaseForm.totalAmount * purchaseForm.gst / 100)
        // }
        if (!purchaseForm.customer) {
            error.customer = "Please select supplier";
            flag = true;
        }
        if (!purchaseForm.invoiceNo) {
            error.invoiceNo = "Please enter invoice number";
            flag = true;
        }
        if (productDetails.length) {
            productDetails.map((item, i) => {
                if (item.productCode === "") {
                    error[`productCode${i + 1}`] = `Please select product name ${i + 1}`
                    flag = true;
                }
                if (item.quantity == 0) {
                    error[`quantity${i + 1}`] = `Please enter quantity ${i + 1}`
                    flag = true;
                }
                if (item.rate == 0) {
                    error[`rate${i + 1}`] = `Please enter rate ${i + 1}`
                    flag = true;
                }
            })
        }
        setFormErrors(error);
        return flag;
    };

    const openCalender = (id) => {
        document.getElementById(id).click();
    };

    const selectValue = (value, name) => {
        if (name === "customer") {
            return supplierOption.find((item) => item.value === value)
        } else if (name === "productCode") {
            return productOption.find((item) => item.value === value) ?? ''
        }
    }

    return (
        <>
            <div className="addEnquiry">
                <div className="position-relative p-4">
                    <div className="mb-4 set-page-heade">
                        <div className="d-flex flex-column">
                            <h2 className='mb-0'>{editId ? "Edit Purchase" : "Add Purchase"}</h2>
                        </div>
                        <div>
                            {editId ?
                                <button className="adduser-btn" onClick={() => Edit()}>Edit</button> : <button className="adduser-btn" onClick={() => Submit()}>
                                    Save
                                </button>}
                            <button
                                className="cancel-button ms-3"
                                onClick={() => navigate("/purchase")}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                    <div className="users-form over-flow-scroll add-purchase-scrollbar">
                        <Row>
                            <Col lg={3}>
                                <div className="users-form-info date-picker mt-3">
                                    <div className="users-label enquery-datepicker">
                                        <label>Invoice Date</label>
                                        <ReactDatePicker
                                            id="EnquiryDatePicker"
                                            dateFormat="dd/MM/yyyy"
                                            selected={purchaseForm.invoiceDate}
                                            placeholderText="Select invoice date"
                                            onChange={(e) => { setPurchaseForm({ ...purchaseForm, invoiceDate: e }); setFormErrors({ ...formErrors, invoiceDate: '' }) }}
                                            autoComplete="off"
                                        />
                                    </div>
                                    {formErrors?.invoiceDate && (<label style={{ color: "red" }}>{formErrors.invoiceDate}</label>)}
                                    <img src={Datepickericon} onClick={() => openCalender("EnquiryDatePicker")} alt="" className="datepickericon" />
                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className="users-form-info mt-3">
                                    <div className="users-label">
                                        <label>Invoice Number</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter invoice number"
                                            value={purchaseForm.invoiceNo}
                                            onChange={(e) => handleChange(e, 'invoiceNo')}
                                            autoComplete="off"
                                        />
                                    </div>
                                    {formErrors?.invoiceNo && (
                                        <label style={{ color: "red" }}>
                                            {formErrors.invoiceNo}
                                        </label>
                                    )}
                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className="users-form-info add-remark-modal mx-0 mt-3">
                                    <div className="multi-select customer-width">
                                        <label>Supplier Name</label>
                                        <Select
                                            placeholder="Select supplier"
                                            value={selectValue(purchaseForm.customer, "customer")}
                                            onChange={(e) => handleChange(e, 'customer')}
                                            options={supplierOption}
                                            className="w-100"
                                        />
                                    </div>
                                    {formErrors?.customer && <span style={{ color: "red" }}>{formErrors.customer}</span>}
                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className="users-form-info mt-3">
                                    <div className="users-label">
                                        <label>Narration</label>
                                        <textarea
                                            type="text"
                                            className="form-control mt-1"
                                            placeholder="Enter narration"
                                            value={purchaseForm.narration}
                                            onChange={(e) => handleChange(e, "narration")}
                                            autoComplete="off"
                                            rows={1}
                                            style={{ height: 'unset' }}
                                        />
                                    </div>
                                    {/* {formErrors?.narration && (<label style={{ color: "red" }}>{formErrors.narration}</label>)} */}
                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className="users-form-info date-picker mt-3">
                                    <div className="users-label">
                                        <label>Product Amount</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter product amount"
                                            value={purchaseForm.productAmount}
                                            onChange={(e) => handleChange(e, "productAmount")}
                                            autoComplete="off"
                                            disabled
                                        />
                                    </div>
                                    {formErrors?.productAmount && (
                                        <label style={{ color: "red" }}>
                                            {formErrors.productAmount}
                                        </label>
                                    )}
                                </div>
                            </Col>
                            {/* <Col lg={3}>
                                <div className="users-form-info date-picker mt-3">
                                    <div className="users-label ">
                                        <label>Discount Amount</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter discount amount"
                                            value={purchaseForm.discountAmount}
                                            onChange={(e) => handleChange(e, "discountAmount")}
                                            autoComplete="off"
                                        />
                                    </div>
                                    {formErrors?.discountAmount && (
                                        <label style={{ color: "red" }}>
                                            {formErrors.discountAmount}
                                        </label>
                                    )}
                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className="users-form-info date-picker mt-3">
                                    <div className="users-label ">
                                        <label>GST(%)</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter GST"
                                            value={purchaseForm.gst ? (purchaseForm.gst) : 0}
                                            onChange={(e) => setPurchaseForm({ ...purchaseForm, gst: (e.target.value) })}
                                            autoComplete="off"
                                        />
                                    </div>
                                    {formErrors?.discountAmount && (
                                        <label style={{ color: "red" }}>
                                            {formErrors.discountAmount}
                                        </label>
                                    )}
                                </div>
                            </Col> */}
                            <Col lg={3}>
                                <div className="users-form-info mt-3">
                                    <div className="users-label">
                                        <label>Total Amount</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter total amount"
                                            value={purchaseForm?.totalAmount?.toFixed(2)}
                                            name="quotationNumber"
                                            onChange={(e) => { handleChange(purchaseForm.gst ? (purchaseForm.totalAmount + (purchaseForm.totalAmount * purchaseForm.gst / 100)).toFixed(2) : purchaseForm.totalAmount.toFixed(2), "totalAmount"); }}
                                            autoComplete="off"
                                            disabled
                                        />
                                    </div>
                                    {formErrors?.totalAmount && (
                                        <label style={{ color: "red" }}>
                                            {formErrors.totalAmount}
                                        </label>
                                    )}
                                </div>
                            </Col>
                        </Row>
                        <div className="users-label users-form-info mt-5">
                            <h3 className='Milestores-title mb-0'>Product Details</h3>
                        </div>
                        <div className="font-size-lg">
                            {productDetails && productDetails.map((data, i) => (
                                <div key={i}>
                                    <div className="d-flex align-items-center gap-3">
                                        <h5>{i + 1}</h5>
                                        <div style={{ flex: 1, ...(i !== productDetails.length - 1 && { paddingRight: "30px" }) }}>
                                            <Row>
                                                <Col xxl={5} xl={5} lg={4} md={12}>
                                                    <div className="small-input">
                                                        <div className="users-form-info add-remark-modal mx-0 mt-3">
                                                            <div className="multi-select">
                                                                <label>Product Name</label>
                                                                {/* <Select
                                                                    placeholder="Select product"
                                                                    value={selectValue(data.productCode, "productCode")}
                                                                    onChange={(e) => handleChangeData(e, i, 'productCode')}
                                                                    options={productOption}
                                                                /> */}
                                                                {/* <div className="users-label">
                                                                    <div className="position-relative select-icon" >
                                                                        <img src={img} className={`${rotateArrow === `priority${i}-${i}` && "rotateArrow"}`} alt="" style={{ marginTop: 2, marginRight: 4 }} />
                                                                        <select
                                                                            className="form-control"
                                                                            value={data?.productCode}
                                                                            onBlur={() => Blur()}
                                                                            onClick={() => Click(`priority${i}-${i}`)}
                                                                            onChange={(e) => {
                                                                                const option = productOption?.find((item) => item.value === e.target.value);
                                                                                handleChangeData(option, i, 'productCode');
                                                                            }}
                                                                            style={{ marginTop: "unset", paddingRight: 30 }}
                                                                        >
                                                                            <option value="" style={{ display: "none" }}>Select Product</option>
                                                                            {productOption?.map((ele, index) => <option key={index} value={ele.value} style={{ overflowWrap: "break-word" }}>{ele.label}</option>)}
                                                                        </select>
                                                                    </div>
                                                                </div> */}
                                                                <Select
                                                                    cacheOptions
                                                                    placeholder="Select Product"
                                                                    onBlur={() => Blur()}
                                                                    value={productOption.find(item => item.value === data.productCode)}
                                                                    options={productOption}
                                                                    onChange={(e) => {
                                                                        const option = productOption?.find((item) => item.value === e.value);
                                                                        handleChangeData(option, i, 'productCode');
                                                                    }}
                                                                    menuPlacement="top"
                                                                    style={{ marginTop: "unset", paddingRight: 30, minHeight: 42 }}
                                                                // maxMenuHeight={100}
                                                                />
                                                            </div>
                                                            {formErrors && formErrors[`productCode${i + 1}`] && <label style={{ color: "red" }}>{formErrors[`productCode${i + 1}`]}</label>}
                                                        </div>
                                                    </div>
                                                </Col>

                                                <Col xxl={5} xl={5} lg={6} md={12}>
                                                    <Row>
                                                        <Col xxl={3} xl={3} lg={3} md={4}>
                                                            <div className="small-input">
                                                                <div className="users-form-info mt-3">
                                                                    <div className="users-label">
                                                                        <label>Quantity</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Enter product quantity"
                                                                            onChange={(e) => handleChangeData(e, i, 'quantity')}
                                                                            value={data.quantity}
                                                                        />
                                                                        <label title="On Hand">OH: {data.showQty && data.showQty}</label>
                                                                    </div>
                                                                    {formErrors && formErrors[`quantity${i + 1}`] && <label style={{ color: "red" }}>{formErrors[`quantity${i + 1}`]}</label>}
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col xxl={3} xl={3} lg={3} md={4}>
                                                            <div className="small-input">
                                                                <div className="users-form-info mt-3">
                                                                    <div className="users-label">
                                                                        <label>Rate</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Enter rate"
                                                                            onChange={(e) => handleChangeData(e, i, 'rate')}
                                                                            value={data.rate}
                                                                        />
                                                                        <label>Rate: {data.showPrice && data.showPrice}</label>
                                                                    </div>
                                                                    {formErrors && formErrors[`rate${i + 1}`] && <label style={{ color: "red" }}>{formErrors[`rate${i + 1}`]}</label>}
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col xxl={3} xl={3} lg={3} md={2}>
                                                            <div className="small-input">
                                                                <div className="users-form-info mt-3">
                                                                    <div className="users-label">
                                                                        <label>GST %</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="GST percentage"
                                                                            onChange={(e) => handleChangeData(e, i, 'gst')}
                                                                            value={data.gst}
                                                                        />
                                                                    </div>
                                                                    {formErrors && formErrors[`gst${i + 1}`] && <label style={{ color: "red" }}>{formErrors[`gst${i + 1}`]}</label>}
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col xxl={3} xl={3} lg={3} md={2}>
                                                            <div className="small-input">
                                                                <div className="users-form-info mt-3">
                                                                    <div className="users-label">
                                                                        <label>Disc %</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Discount percentage"
                                                                            onChange={(e) => handleChangeData(e, i, 'discount')}
                                                                            value={data.discount}
                                                                        />
                                                                    </div>
                                                                    {formErrors && formErrors[`discount${i + 1}`] && <label style={{ color: "red" }}>{formErrors[`discount${i + 1}`]}</label>}
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col xxl={2} xl={2} lg={2} md={12}>
                                                    <div className="small-input">
                                                        <div className="users-form-info mt-3">
                                                            <div className="users-label">
                                                                <label>Amount</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Enter amount"
                                                                    onChange={(e) => handleChangeData(e, i, 'amount')}
                                                                    value={(data.finalAmount).toFixed(2)}
                                                                    disabled
                                                                />
                                                            </div>
                                                            {formErrors && formErrors[`amount${i + 1}`] && <label style={{ color: "red" }}>{formErrors[`amount${i + 1}`]}</label>}
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                        {/* <Col xxl={3} xl={6} lg={12} md={12}>
                                                    <div className="small-input">
                                                        <div className="users-form-info mt-3">
                                                            <div className="users-label">
                                                                <label>Description</label>
                                                                <textarea
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Enter description"
                                                                    onChange={(e) => handleChangeData(e, i, 'description')}
                                                                    value={data.description}
                                                                    rows="1"
                                                                    style={{ height: 'unset' }}
                                                                />
                                                            </div>
                                                            {formErrors && formErrors[`description${i + 1}`] && <label style={{ color: "red" }}>{formErrors[`description${i + 1}`]}</label>}
                                                        </div>
                                                    </div>
                                                </Col> */}
                                        <div className="users-form-info">
                                            {i === productDetails.length - 1 && (
                                                <img
                                                    src={Plusicon}
                                                    onClick={() => { addData() }}
                                                    width="30"
                                                    height="30"
                                                    style={{ cursor: "pointer" }}
                                                />
                                            )}
                                            {productDetails.length > 1 && <img
                                                src={Minusicon}
                                                onClick={() => Remove(i)}
                                                width="33"
                                                height="33"
                                                style={{ cursor: "pointer" }}
                                            />}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddPurchase;

import { ApiGet, ApiPost, BaseURL } from '../helper/API/ApiData';

const usePaginationHooks = () => {

    const getAllSales = async (page, limit, filter, sorted_column, sorted_order) => {
        return await ApiGet(`sell/get_all_sell/?page=${page}&limit=${limit}&from=${filter.From ? filter.From : ''}&to=${filter.To ? filter.To : ''}&search=${filter.Search ? filter.Search : ''}&searchType=${filter.SearchType ? filter.SearchType : ''}&sorted_column=${sorted_column}&sorted_order=${sorted_order}`)
    }
    // const ExportAllSales = async (filter) => {
    //     const URL = `${BaseURL}sell/download/?from=${filter.From}&to=${filter.To}&search=${filter.Search ? filter.Search : ''}&searchType=${filter.SearchType ? filter.SearchType : ''}`
    //     return URL
    // }
    const getAllEnquiries = async (page, limit, select, search, sorted_column, sorted_order) => {
        return await ApiGet(`enquiry/get_all_enquiries/?page=${page}&limit=${limit}&select=${select}&search=${search}&sorted_column=${sorted_column}&sorted_order=${sorted_order}`)
    }
    const getAllTask = async (body, page, limit) => {
        return await ApiPost(`task/get_all_task/?page=${page}&limit=${limit}`, body)
    }
    const getAllProjects = async (type, role, page, limit, search, sorted_column, sorted_order) => {
        return await ApiGet(`project/get_all_projects?type=${type}&role=${role}&page=${page}&limit=${limit}&search=${search}&sorted_column=${sorted_column}&sorted_order=${sorted_order}`)
    }
    const getFinalMaterialByProjects = async (id, page, limit, search, sorted_column, sorted_order) => {
        return await ApiGet(`final_material/get_final_material_by_project/${id}?page=${page}&limit=${limit}&search=${search}&sorted_column=${sorted_column}&sorted_order=${sorted_order}`)
    }
    const getAllSiteReport = async (projectId, date, page, limit, search, sorted_column, sorted_order) => {
        return await ApiGet(`daily_site_reports/get_all_site_reports?projectId=${projectId}&date=${date}&page=${page}&limit=${limit}&search=${search}&sorted_column=${sorted_column}&sorted_order=${sorted_order}`)
    }
    const getAllpurchase = async (page, limit, search, sorted_column, sorted_order) => {
        return await ApiGet(`purchase/get_all_purchase?page=${page}&limit=${limit}&search=${search}&sorted_column=${sorted_column}&sorted_order=${sorted_order}`)
    }
    const getAllPurchaseRequests = async (page, limit, search, status, sorted_column, sorted_order, with_waiting_for_approval_status) => {
        return await ApiGet(`purchase_request/get_all_purchase_request/?page=${page}&limit=${limit}&search=${search}&status=${status}&sorted_column=${sorted_column}&sorted_order=${sorted_order}&with_waiting_for_approval_status=${with_waiting_for_approval_status}`)
    }
    const getPRByProjectWithPaginate = async (id, page, limit, search, sorted_column, sorted_order) => {
        return await ApiGet(`purchase_request/get_purchase_request_by_project_with_pagination/${id}?page=${page}&limit=${limit}&search=${search}&sorted_column=${sorted_column}&sorted_order=${sorted_order}`)
    }
    const getAllProducts = async (page, limit, search, sorted_column, sorted_order, projectId) => {
        return await ApiGet(`product/get_all_products?page=${page}&limit=${limit}&search=${search}&sorted_column=${sorted_column}&sorted_order=${sorted_order}`)
    }
    const getAllPRProductByProject = async (page, limit, search, status, sorted_column, sorted_order, projectId) => {
        return await ApiGet(`purchase_request/product_wise_purchase_request?project=${projectId}&status=${status}&page=${page}&limit=${limit}&search=${search}&sorted_column=${sorted_column}&sorted_order=${sorted_order}`)
    }
    const getAllReturnMaterials = async (page, limit, search, sorted_column, sorted_order) => {
        return await ApiGet(`return_material/get_all_return_material?page=${page}&limit=${limit}&search=${search}&sorted_column=${sorted_column}&sorted_order=${sorted_order}`)
    }
    const getAllEmployeeReports = async (page, limit, search, sorted_column, sorted_order) => {
        return await ApiGet(`employee_reports/get_all_employee_reports?page=${page}&limit=${limit}&search=${search}&sorted_column=${sorted_column}&sorted_order=${sorted_order}`)
    }
    const getAllAssetsNonProjects = async (page, limit, search, sorted_column, sorted_order) => {
        return await ApiGet(`assets/get_all_assets_non_project?page=${page}&limit=${limit}&search=${search}&sorted_column=${sorted_column}&sorted_order=${sorted_order}`)
    }
    const getAllNoc = async (page, limit, search, sorted_column, sorted_order) => {
        return await ApiGet(`noc/get_all_nocs?page=${page}&limit=${limit}&search=${search}&sorted_column=${sorted_column}&sorted_order=${sorted_order}`)
    }
    const getAllCustomer = async (page, limit, search, sorted_column, sorted_order) => {
        return await ApiGet(`customer/get_all_customers?page=${page}&limit=${limit}&search=${search}&sorted_column=${sorted_column}&sorted_order=${sorted_order}`)
    }
    const getAllContractor = async (page, limit, search, sorted_column, sorted_order) => {
        return await ApiGet(`contractor/get_all_contractors?page=${page}&limit=${limit}&search=${search}&sorted_column=${sorted_column}&sorted_order=${sorted_order}`)
    }
    const getAllUser = async (page, limit, search, sorted_column, sorted_order) => {
        return await ApiGet(`users/getAllUser?page=${page}&limit=${limit}&search=${search}&sorted_column=${sorted_column}&sorted_order=${sorted_order}`)
    }
    const getAllAccounts = async (page, limit, startDate, endDate, type, body) => {
        return await ApiPost(`account/get_all_account?startDate=${startDate}&endDate=${endDate}&type=${type}&page=${page}&limit=${limit}`, body)
    }
    const getAllBillEntryByCustomers = async (page, limit, id, sorted_column, sorted_order, custom_filter_column_name, custom_filter_column_value, startDate, endDate, projectId) => {
        return await ApiGet(`bill_entry/get_all_bill_entry_by_customer?cId=${id}&page=${page}&limit=${limit}&sorted_column=${sorted_column}&sorted_order=${sorted_order}&custom_filter_column_name=${custom_filter_column_name}&custom_filter_column_value=${custom_filter_column_value}&startDate=${startDate}&endDate=${endDate}&projectId=${projectId}`)
    }
    const getAllTermAndConditions = async (page, limit, sorted_column, sorted_order) => {
        return await ApiGet(`termAndCondition/get_all_term_and_condition?page=${page}&limit=${limit}&sorted_column=${sorted_column}&sorted_order=${sorted_order}`)
    }
    const getquotationbyId = async (id, page, limit) => {
        return await ApiGet(`quotation/quotation_by_id/${id}?page=${page}&limit=${limit}`)
    }
    const getProjectHistorybyId = async (search, projectId, productId, page, limit, sorted_column, sorted_order) => {
        return await ApiGet(`project_material/getAll_project_materal_history_by_projectId_and_productId?search=${search}&projectId=${projectId}&productId=${productId}&page=${page}&limit=${limit}&sorted_column=${sorted_column}&sorted_order=${sorted_order}`)
    }
    const generateLabourBill = async (contractor, projectId, status, labour_bill_customer_Id, labour_bill_invoice_date) => {
        return await ApiGet(`project_material/generate_labor_bill_by_projectId_and_contractor?projectId=${projectId}&contractor=${contractor}&send_for_admin_approval=${status}&labour_bill_customer_Id=${labour_bill_customer_Id}&labour_bill_invoice_date=${labour_bill_invoice_date}`)
    }
    const generateLabourByLabourBillInvoiceNo = async (labour_bill_invoice_no) => {
        return await ApiGet(`project_material/generate_labor_bill_by_labour_bill_invoice_no?labour_bill_invoice_no=${labour_bill_invoice_no}`)
    }
    const getAllGenerateLabourBill = async (search, page, limit, sorted_column, sorted_order, projectId) => {
        return await ApiGet(`project_material/getAll_generatelaborbill_in_project_materal_history?search=${search}&page=${page}&limit=${limit}&sorted_column=${sorted_column}&sorted_order=${sorted_order}&projectId=${projectId}`)
    }

    const getReturnMaterialByProject = async (page, limit, search, sorted_column, sorted_order, projectId) => {
        return await ApiGet(`return_material/return_material_by_project/${projectId}?page=${page}&limit=${limit}&search=${search}&sorted_column=${sorted_column}&sorted_order=${sorted_order}`)
    }

    return {
        getAllSales, getAllEnquiries, getAllTask, getAllProjects, getFinalMaterialByProjects, getAllSiteReport, getAllpurchase, getAllPurchaseRequests, getAllProducts,
        getAllReturnMaterials, getquotationbyId, getPRByProjectWithPaginate, getAllPRProductByProject, getProjectHistorybyId, generateLabourBill,
        getAllEmployeeReports, getAllAssetsNonProjects, getAllNoc, getAllCustomer, getAllContractor, getAllUser, getAllAccounts, getAllBillEntryByCustomers, getAllTermAndConditions,
        getAllGenerateLabourBill, generateLabourByLabourBillInvoiceNo, getReturnMaterialByProject

    }
}
export default usePaginationHooks;

import React, { useContext, useEffect, useRef, useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { fileRemoveAction, fileUploadAction } from '../../redux/actions/generalAction'
import { editProjectAction, getProjectByIdAction } from '../../redux/actions/projectAction'
import Datepickericon from '../../assets/image/date-picker-icon.png'
import Viewicon from '../../assets/image/view.png'
import Whiteeye from '../../assets/image/white-eye.png'
import { toast } from 'react-toastify'
import Arrowimg from '../../assets/image/select.png'
import { EDIT_PROJECT, FILE_UPLOAD, GET_PRODUCT_BY_FINAL_MATERIAL, GET_PROJECT_BY_ID } from '../../redux/type'
import { getAllCustomerAction } from '../../redux/actions/customerAction'
import AddPurchaseRequest from '../../components/modals/AddPurchaseRequest'
import { Col, Dropdown, Form, Row } from 'react-bootstrap'
import Plusicon from "../../assets/image/plus.png";
import Minusicon from "../../assets/image/minus.png";
import downloadWhite from "../../assets/image/download-white.png";
import { AllStatus, Unit, UserRoles } from '../../helper/Constant'
import SweetAlert from 'react-bootstrap-sweetalert';
import AddContractor from '../../components/modals/AddContractor'
import { getAllContractorAction } from '../../redux/actions/contractorAction'
import { API } from '../../config/API/api.config'
import { GET_PURCHASE_REQUEST_BY_ID } from '../../redux/type';
import { getAllUserAction, getProjectManagerAction } from '../../redux/actions/userAction'
import AssetsProject from '../../components/modals/AssetsProject'
import removeImg from '../../assets/image/delete-red.png';
import dote from '../../assets/image/dote-images.png';
import { RoleContext } from '../../helper/RoleProvider'
import Select from 'react-select';
import { getPurchaseRequestByProjectAction } from '../../redux/actions/purchaseRequest'
import ViewFinalQuotation from '../../components/modals/ViewFinalQuotation'
import { getAllProductAction, getProductByFinalMaterialAction, getProductDropDownAction } from '../../redux/actions/productAction'
import FinalMaterial from '../../components/modals/FinalMaterial'
import CopyImg from '../../assets/image/copy-white.png'
import { ColumnGroup } from 'ag-grid-community'
import { getQuotationByEnquiryIdAction } from '../../redux/actions/quotationAction'
import { downloadQuoatationFile } from '../../helper/utils'
import { getQuotationByIdAction } from '../../redux/actions/quotationAction'
import ProductWisePr from '../../components/modals/ProductWisePR'
import { getEnquiryByIdAction } from '../../redux/actions/enquiryAction'
import moment from 'moment'

const ProjectEdit = () => {
    const location = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const userRole = useContext(RoleContext);
    const toastSuccess = () => toast.success('Project updated successfully');
    const toastError = () => toast.error('Please check required field');
    const toestErrorNoProject = () => toast.error('Project not found');
    const queryParams = new URLSearchParams(location.search)
    const projectId = queryParams.get("projectId")
    const [user, setUser] = useState({
        id: projectId,
        enq_id: '',
        partyName: '',
        location: '',
        contractor: '',
        contactPersonName: '',
        contactPersonNumber: '',
        estimatedDelivery: '',
        startDate: '',
        finalQuatation: '',
        status: '',
        workOrder: [],
        file: '',
        customer: '',
        createdBy: '',
        projectManager: '',
    })
    const [milestone, setMilestone] = useState([
        {
            step: "",
            status: "Pending",
            estimatedDelivery: "",
            description: "",
            deliveredOn: "",
        },
    ])

    const unitOption = [
        { value: Unit.KG, label: Unit.KG },
        { value: Unit.LTR, label: Unit.LTR },
        { value: Unit.NOS, label: Unit.NOS },
        { value: Unit.MTR, label: Unit.MTR },
        { value: Unit.FEET, label: Unit.FEET },
    ]
    let numberRegex = /^[0-9\b]+$/;
    const [productOption, setProductOption] = useState([])
    const [isProductWiseData, setIsProductWiseData] = useState(false)
    const [viewFinalQuotation, setViewFinalQuotation] = useState(false)
    const [stepLength, setStepLength] = useState()
    const [quotationOption, setQuotationOption] = useState([])
    const [file, setFile] = useState(undefined)
    const [workOrderFile, setWorkOrderFile] = useState(undefined)
    const [fileName, setFileName] = useState('')
    const [formErrors, setFormErrors] = useState()
    const [statusId, setStatusId] = useState('')
    const [purchaseReqTog, setPurchaseReqTog] = useState(false)
    const [contactorTog, setContactorTog] = useState(false)
    const [purchaseRequestId, setPurchaseRequestId] = useState('')
    const [confirmTog, setConfirmTog] = useState(false)
    const [assetsTog, setAssetsTog] = useState(false)
    const [contractorOption, setContractorOption] = useState([])
    const [customerOption, setCustomerOption] = useState([])
    const [projectManagerOption, setProjectManagerOption] = useState([])
    const [purchaseRequest, setPurchaseRequest] = useState([])
    const [userOption, setUserOption] = useState([])
    const [enquiryId, setenquiryId] = useState('')
    const [finalMaterialTog, setFinalMaterialTog] = useState()
    const getQuotationById = useSelector((state) => state.get_Quotation_By_Id.getQuotationById)
    const userData = useSelector((state) => state.get_Project_By_Id.getProjectById)
    const fileData = useSelector((state) => state.file_Upload.fileUpload)
    const editProjectData = useSelector((state) => state.edit_Project.editProject)
    const customerData = useSelector((state) => state.get_All_Customer.getAllCustomer.data)
    const getAllContractor = useSelector((state) => state.get_All_Contractor.getAllContractor)
    const projectManagerData = useSelector((state) => state.get_Project_Manager.getProjectManager.data)
    const purchaseRequestByProjectId = useSelector((state) => state.get_Purchase_Request_By_Project.getPurchaseRequestByProject)
    const getAllUserData = useSelector((state) => state.get_All_User.getAllUser)
    const productData = useSelector((state) => state.get_Product_Drop_Down.getProductDropDown.data)
    const getQuotationByEnquiryIdData = useSelector((state) => state.get_Quotation_By_ProjectId.getQuotationByEnquiryId)
    const getAllProductData = useSelector((state) => state.get_All_Product.getAllProduct)
    const getEnquiryByIdData = useSelector((state) => state.get_Enquiry_By_Id.getEnquiryById)
    const copyLinkSuccess = () => toast.success('Project report link copied.');

    useEffect(() => {
        if (enquiryId) {
            dispatch(getQuotationByEnquiryIdAction(enquiryId))
            dispatch(getEnquiryByIdAction(enquiryId))
        }
    }, [enquiryId])

    useEffect(() => {
        dispatch(getAllProductAction())
        dispatch(getAllCustomerAction())
        dispatch(getAllContractorAction())
        dispatch(getProjectManagerAction())
        dispatch(getAllUserAction())
        dispatch(getProductDropDownAction())
    }, [])

    useEffect(() => {
        if (projectId) {
            dispatch(getProjectByIdAction(projectId))
            dispatch(getPurchaseRequestByProjectAction(projectId))
        }
        return () => {
            dispatch({
                type: GET_PRODUCT_BY_FINAL_MATERIAL,
                payload: []
            })
        }
    }, [projectId])

    useEffect(() => {
        if (purchaseRequestByProjectId && purchaseRequestByProjectId.data) {
            setPurchaseRequest(purchaseRequestByProjectId.data)
        }
    }, [purchaseRequestByProjectId])

    useEffect(() => {
        if (getAllContractor && getAllContractor.data) {
            setContractorOption(getAllContractor.data.sort((a, b) => a.name.localeCompare(b.name, undefined, { case: 'upper' })).map((ele) => {
                return {
                    label: ele.name,
                    value: ele._id
                }
            }))
        }
    }, [getAllContractor])

    useEffect(() => {
        if (customerData) {
            let temp = customerData.sort((a, b) => a.accountName.localeCompare(b.accountName, undefined, { case: 'upper' }))
            setCustomerOption(temp.map((ele) => {
                return {
                    label: ele.accountName,
                    value: ele._id
                }
            }))
        }
    }, [customerData])

    useEffect(() => {
        if (productData && productData.length > 0) {
            let temp = productData.sort((a, b) => a.productName.localeCompare(b.productName, undefined, { case: 'upper' }))
            setProductOption(temp.map((ele) => {
                return {
                    value: ele._id,
                    label: ele.productName
                }
            }))
        }
    }, [productData])

    useEffect(() => {
        if (projectManagerData) {
            setProjectManagerOption(projectManagerData.sort((a, b) => a.name.localeCompare(b.name, undefined, { case: 'upper' })))
        }
    }, [projectManagerData])

    useEffect(() => {
        if (file) {
            console.log('file', file)
            let formData = new FormData()
            formData.append("files", file);
            dispatch(fileUploadAction(formData))
        }
    }, [file])

    useEffect(() => {
        if (workOrderFile) {
            let formData = new FormData()
            formData.append("files", workOrderFile);
            dispatch(fileUploadAction(formData))
        }
    }, [workOrderFile])

    useEffect(() => {
        if (fileData) {
            if (fileName === "workOrderFile") {
                let temp = [...user.workOrder]
                temp.push(fileData.url)
                setUser({ ...user, workOrder: temp })
            } else if (fileName === "measurementFile") {
                let newData = [...user.file]
                newData.push(fileData.url)
                setUser({ ...user, file: newData })
            }
        }
    }, [fileData])

    const fileRemove = (filename, i, name) => {
        let body = {
            file: filename.split('/')[1] ? filename.split('/')[1] : filename
        }
        dispatch(fileRemoveAction(body))
        if (name === 'removeFile') {
            let temp = [...user.file]
            temp.splice(i, 1)
            setUser({ ...user, file: temp })
        } else if (name === 'removeFileWorkOrder') {
            let val = [...user.workOrder]
            val.splice(i, 1)
            setUser({ ...user, workOrder: val })
        }
    }

    useEffect(() => {
        if (userData.message === "Project not found") {
            navigate('/project')
            toestErrorNoProject(true)
        }
        if (userData && userData.data) {
            dispatch(getQuotationByIdAction(userData.data.finalQuatation));
            setUser({ ...user, enq_id: userData.data.enq_id, partyName: userData.data.partyName, location: userData.data.location, contactPersonName: userData.data.contactPersonName, contactPersonNumber: userData.data.contactPersonNumber, estimatedDelivery: userData.data.estimatedDelivery ? new Date(userData.data.estimatedDelivery) : '', file: userData.data.file ? userData.data.file : [], contractor: contractorOption.filter((ele) => userData.data?.contractor.includes(ele.value)), startDate: userData.data.startDate ? new Date(userData.data.startDate) : '', finalQuatation: userData.data.finalQuatation ? userData.data.finalQuatation : '', workOrder: userData.data.workOrder ? userData.data.workOrder : [], status: userData.data.status, customer: userData.data.customer, createdBy: userData.data.createdBy, projectManager: userData.data.projectManager })
            {
                userData.data.projectDetail.length > 0 && setMilestone(userData.data.projectDetail.map((item) => {
                    return {
                        _id: item._id,
                        step: item.step,
                        estimatedDelivery: item.estimatedDelivery ? new Date(item.estimatedDelivery) : '',
                        deliveredOn: item.deliveredOn ? new Date(item.deliveredOn) : '',
                        description: item.description,
                        status: item.status,
                    }
                }))
            }
            setenquiryId(userData.data.enq_id)
            setStepLength(userData.data.projectDetail.length)
            setQuotationOption(userData.data.nextFollowups.nextFollowups.map((item) => {
                return {
                    value: item.quoationUpdate && item.quoationUpdate,
                    label: item.quoationUpdate && item.quoationUpdate.split('/')[1]
                }
            }))
        }
    }, [userData])

    const handleChangeData = (e, i, name) => {
        setFormErrors({ ...formErrors, [`${name}${i + 1}`]: '' })
        let val = [...milestone];
        if (name === 'step') {
            val[i].step = e.target.value;
            setMilestone(val);
        } else if (name === "status") {
            val[i].status = e.target.checked ? 'Done' : 'Pending';
            setMilestone(val);
        } else if (name === "estimatedDelivery") {
            val[i].estimatedDelivery = e;
            setMilestone(val);
        } else if (name === "description") {
            val[i].description = e.target.value;
            setMilestone(val);
        } else if (name === "deliveredOn") {
            console.log("🚀 ~ handleChangeData ~ e:", e)
            val[i].deliveredOn = e;
            setMilestone(val);
        }
    };

    const GetDateValues = (dateString) => {
        const date = new Date(dateString ?? "");
        const year = date?.getFullYear();
        const month = (date?.getMonth() + 1)?.toString()?.padStart(2, '0');
        const day = date?.getDate()?.toString()?.padStart(2, '0');
        const formattedDate = `${year ?? ""}-${month ?? ""}-${day ?? ""}`;
        return (formattedDate);
    }

    const Edit = () => {
        if (validation()) {
            toastError(true)
            return
        }
        const body = {
            ...user,
            status: AllStatus.approved,
            projectDetail: milestone,
            contractor: user.contractor.map((ele) => ele.value),
        }
        dispatch(editProjectAction(body))
    }

    useEffect(() => {
        if (editProjectData && editProjectData.data) {
            dispatch({
                type: EDIT_PROJECT,
                payload: []
            })
            navigate('/project')
            toastSuccess()
        }
    }, [editProjectData])

    useEffect(() => {
        if (getAllUserData && getAllUserData.data) {
            setUserOption(getAllUserData.data.map((ele) => {
                return {
                    value: ele._id,
                    label: `${ele.name} - ${ele.role}`
                }
            }))
        }
    }, [getAllUserData])

    const handleChange = (e) => {
        let regex = /^[0-9\b]+$/;
        if (e.target.value === "" || regex.test(e.target.value)) {
            setUser({ ...user, contactPersonNumber: e.target.value })
        }
    }

    const handleChangeMulti = (e, name) => {
        setFormErrors({ ...formErrors, [name]: '' })
        if (name === "finalQuatation") {
            setUser({ ...user, [name]: e })
        } else if (name === "projectManager") {
            setUser({ ...user, [name]: e.value })
        }
    }

    const validation = () => {
        let flag = false
        const error = {};
        if (!user.enq_id) {
            error.enq_id = "Please enter enquiryid"
            flag = true
        }
        if (!user.partyName) {
            error.partyName = "Please enter party name"
            flag = true
        }
        if (!user.location) {
            error.location = "Please enter location"
            flag = true
        }
        if (!user.contactPersonName) {
            error.contactPersonName = "Please enter contact person name"
            flag = true
        }
        if (!user.contactPersonNumber) {
            error.contactPersonNumber = "Please enter contact person number"
            flag = true
        } else if (user.contactPersonNumber.length < 10) {
            error.contactPersonNumber = "Please enter valid contact person number"
            flag = true
        }
        if (!user.estimatedDelivery) {
            error.estimatedDelivery = "Please select estimated delivery"
            flag = true
        }
        if (!user.startDate) {
            error.startDate = "Please select start date"
            flag = true
        }
        // if (!user.finalQuatation.length) {
        //     error.finalQuatation = "Please enter finalQuatation"
        //     flag = true
        // }
        if (!user.customer) {
            error.customer = "Please select customer"
            flag = true
        }
        if (!user.projectManager) {
            error.projectManager = "Please select project manager"
            flag = true
        }
        if (!user.contractor.length) {
            error.contractor = "Please select contractor"
            flag = true
        }
        if (userData.data.status === AllStatus.active && !user.status) {
            error.status = "Please select status"
            flag = true
        }
        if (milestone.length) {
            milestone.map((item, i) => {
                if (item.step === '') {
                    error[`step${i + 1}`] = `Please enter name ${i + 1}`
                    flag = true
                }
                if (item.estimatedDelivery === '') {
                    error[`estimatedDelivery${i + 1}`] = `Please select estimated delivery ${i + 1}`
                    flag = true
                }
                if (item.status === AllStatus.Done && item.deliveredOn === '') {
                    error[`deliveredOn${i + 1}`] = `Please select deliveredOn ${i + 1}`
                    flag = true
                }
                // if (item.status === '' || item.status === 'Select Status') {
                //     error[`status${i + 1}`] = `Please select status ${i + 1}`
                //     flag = true
                // }
                if (item.description === '') {
                    error[`description${i + 1}`] = `Please enter description ${i + 1}`
                    flag = true
                }
            })
        }
        setFormErrors(error);
        return flag
    }

    const addData = () => {
        let val = [...milestone];
        val.push({
            step: "",
            status: "Pending",
            estimatedDelivery: "",
            description: "",
            deliveredOn: "",
        });
        setMilestone(val);
    };

    const Remove = (i) => {
        if (i + 1 > stepLength || userRole === "Admin") {
            if (milestone.length > 1) {
                let val = [...milestone];
                val.splice(i, 1);
                setMilestone(val);
            }
        }
    };

    const Blur = () => {
        setStatusId('')
    }

    const Click = (name) => {
        if (statusId === name) {
            setStatusId('')
        } else {
            setStatusId(name)
        }
    }

    const Confirm = () => {
        if (validation()) {
            toastError(true)
            return
        }
        setConfirmTog(true)
    }

    const onCopy = (id) => {
        navigator.clipboard.writeText(`${process.env.REACT_APP_URL}/project-report?id=${id}`);
        copyLinkSuccess(true)
    }

    const confirmAndSave = () => {
        const body = {
            ...user,
            status: user.status === AllStatus.approved ? AllStatus.active : user.status,
            projectDetail: milestone,
            contractor: user.contractor.map((ele) => ele.value),
        }
        // console.log('body', body)
        dispatch(editProjectAction(body))
    }

    const inputElement = useRef()

    const focusInput = () => {
        inputElement.current.click()
    }

    const openCalender = (id) => {
        document.getElementById(id).click()
    }

    const goToDailyReportPage = () => {
        navigate(`/daily-report?projectId=${user.id}`)
    }

    const HideContract = () => {
        setContactorTog(false)
        dispatch({
            type: FILE_UPLOAD,
            payload: []
        })
    }

    const hidePR = () => {
        setPurchaseReqTog(false)
        setPurchaseRequestId('')
        dispatch({
            type: GET_PURCHASE_REQUEST_BY_ID,
            payload: []
        })
    }

    const HideAssets = () => {
        setAssetsTog(false)
        dispatch({
            type: FILE_UPLOAD,
            payload: []
        })
    }

    const fileOpen = (name) => {
        window.open(`${API.endpoint}/${name}`, "_blank")
    }

    useEffect(() => {
        if (getAllUserData && getAllUserData.data) {
            setUserOption(getAllUserData.data.map((ele) => {
                return {
                    value: ele._id,
                    label: `${ele.name} - ${ele.role}`
                }
            }))
        }
    }, [getAllUserData])

    useEffect(() => {
        if (assetsTog) {
            dispatch({
                type: FILE_UPLOAD,
                payload: []
            })
        }
    }, [assetsTog])

    const UploadClick = () => {
        document.getElementById('workOrder').click()
    }

    useEffect(() => {
        return () => {
            dispatch({
                type: GET_PROJECT_BY_ID,
                payload: []
            })
        }
    }, [])

    const HideFinalMaterial = () => {
        setFinalMaterialTog(false)
    }

    const selectValue = (value, name) => {
        if (name === "customer") {
            return customerOption.find((ele) => ele.value === value)
        } else if (name === "finalQuatation") {
            // return quotationOption.filter((ele) => value.includes(ele.value))
            return value
        } else if (name === "projectManager") {
            return userOption.find((item) => item.value === value)
        } else if (name === "product") {
            return productOption.find((ele) => ele.value === value)
        } else if (name === "unit") {
            return unitOption.find((ele) => ele.value === value)
        }
    }

    const onDownload = async (id) => {
        if (id) {
            const temp = id.split('.').length
            if (temp > 1) {
                window.open(`${API.endpoint}/${id}`, "_blank")
            } else {
                let data = getQuotationByEnquiryIdData.data.find((ele) => ele._id === id)
                // const customerdata = customerData.find((ele) => ele._id === user.customer)
                try {
                    let productdata = []
                    data?.items.map((element) => {
                        const ele = getAllProductData.data.find((ele) => ele?.productCode === element?.productCode)
                        productdata.push({ ...ele, quantity: element?.reqQuantity, discount: element?.discount ?? 0, retailSalePrice: element?.retailSalePrice ?? 0 })
                    })
                    // productdata.sort((a, b) => {
                    //     if (a.productName < b.productName) return -1;
                    //     if (a.productName > b.productName) return 1;
                    //     return 0;
                    // });

                    data={
                        ...data,
                        partyName: getEnquiryByIdData?.data?.partyName,
                        email: getEnquiryByIdData?.data?.email,
                        contactPersonNumber: getEnquiryByIdData?.data?.contactPersonNumber,
                        enquiryDate: moment(getEnquiryByIdData?.data?.enquiryDate).format("DD/MM/YYYY"),
                        visitDate: moment(getEnquiryByIdData?.data?.visitDate).format("DD/MM/YYYY"),
                        location: getEnquiryByIdData?.data?.location,
                        googleLocation: getEnquiryByIdData?.data?.googleLocation
                    }
                    downloadQuoatationFile(productdata, 'Quotation', data, "Enquiry");
                } catch (err) {
                    console.log(err)
                }

            }
        }
    }

    return (
        <>
            <div className='projectedit project-edit-section'>
                <div className='parent-dashboard'>
                    <div className='mb-4 set-page-heade'>
                        <h2 className='mb-0'>Project</h2>
                        <div className="noc-form-btn final-material-button d-flex">
                            <button className="adduser-btn ms-3 mt-3 mt-lg-0" onClick={() => onCopy(projectId)}>
                                <img src={CopyImg} style={{ cursor: "pointer", height: "15px" }} className='' title='Copy project report link' alt='' />
                            </button>
                            <button className="adduser-btn ms-3 mt-3 mt-lg-0" onClick={() => { navigate(`/project/edit/ProjectMaterial?projectId=${projectId}`) }}>{"Project Material"}</button>
                            <button className="adduser-btn ms-3 mt-3 mt-lg-0" onClick={() => setFinalMaterialTog(true)}>{"Final Material"}</button>
                            {/* <button className="adduser-btn ms-3 mt-3 mt-lg-0" onClick={() => setAssetsTog(true)}>{"Assets"}</button> */}
                            <button className="adduser-btn ms-3 mt-3 mt-lg-0" onClick={() => setPurchaseReqTog(true)}>{"Add PR"}</button>
                            <button className="adduser-btn ms-3 mt-3 mt-lg-0" onClick={() => goToDailyReportPage()}>{"See Daily Report"}</button>
                            {/* {(user.status === "Approved") && <button className="adduser-btn ms-3 mt-3 mt-lg-0" onClick={() => Edit()}>{"Draft"}</button>} */}
                            <button className="adduser-btn ms-3 mt-3 mt-lg-0" onClick={() => Confirm()} >{'Save'}</button>
                            <button className="cancel-button ms-3 mt-3 mt-lg-0" onClick={() => navigate("/project")}>{'Cancel'}</button>
                            <Dropdown className='dropdown-modal mt-3 mt-lg-0'>
                                <Dropdown.Toggle id="dropdown-basic">
                                    <img src={dote} width="20px" height="20px" />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {/* <Dropdown.Item onClick={() => navigate(`/purchaserequest?ProjectId=${projectId}`)}>Purchase Request</Dropdown.Item> */}
                                    <Dropdown.Item onClick={() => setIsProductWiseData(true)}>Product Wise Purchase Request</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                    <div className="users-form addEnquiry">
                        <Row>
                            <Col xxl={4} sm={6}>
                                <div className="users-form-info mt-3">
                                    <div className="users-label">
                                        <label>Project Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter project name"
                                            name="name"
                                            value={user.partyName}
                                            onChange={(e) => setUser({ ...user, partyName: e.target.value })}
                                            autoComplete="off"
                                            disabled={userRole !== "Admin"}
                                        />
                                    </div>
                                    {formErrors?.partyName && <label style={{ color: "red" }}>{formErrors.partyName}</label>}
                                </div>
                            </Col>
                            <Col xxl={4} sm={6}>
                                <div className="users-form-info mt-3">
                                    <div className="users-label">
                                        <label>Location</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter location"
                                            value={user.location}
                                            onChange={(e) => setUser({ ...user, location: e.target.value })}
                                            autoComplete="off"
                                            disabled={userRole !== "Admin"}
                                        />

                                    </div>
                                    {formErrors?.location && <label style={{ color: "red" }}>{formErrors.location}</label>}
                                </div>
                            </Col>
                            <Col xxl={4} sm={6}>
                                <div className="users-form-info mt-3">
                                    <div className="users-label">
                                        <label>Contact Person Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="exampleInputPassword1"
                                            placeholder="Enter contact person name"
                                            value={user.contactPersonName}
                                            onChange={(e) => setUser({ ...user, contactPersonName: e.target.value })}
                                            autoComplete="off"
                                            disabled={userRole !== "Admin"}
                                        />
                                    </div>
                                    {formErrors?.contactPersonName && <label style={{ color: "red" }}>{formErrors.contactPersonName}</label>}
                                </div>
                            </Col>
                            <Col xxl={4} sm={6}>
                                <div className="users-form-info mt-3">
                                    <div className="users-label">
                                        <label>Contact Person No.</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="exampleInputPassword1"
                                            placeholder="Enter contact person number"
                                            value={user.contactPersonNumber}
                                            onChange={(e) => handleChange(e)}
                                            autoComplete="off"
                                            maxLength={10}
                                            disabled={userRole !== "Admin"}
                                        />
                                    </div>
                                    {formErrors?.contactPersonNumber && <label style={{ color: "red" }}>{formErrors.contactPersonNumber}</label>}
                                </div>
                            </Col>
                            <Col xxl={4} sm={6} className="start-estimated-date">
                                <div className="users-form-info date-picker enquery-datepicker mt-3 me-0">
                                    <div className="users-label position-relative">
                                        <label>Start Date</label>
                                        <ReactDatePicker id='startDate' dateFormat="dd/MM/yyyy" className='datepicker-input' selected={user.startDate} placeholderText="Select Date" onChange={(e) => { setUser({ ...user, startDate: e }); setFormErrors({ ...formErrors, startDate: '' }) }} autoComplete='off' disabled={userRole !== "Admin" && (user.status !== "Approved")} />
                                        <img src={Datepickericon} onClick={() => openCalender('startDate')} alt="" className='datepickericon' />
                                    </div>
                                    {formErrors?.startDate && <label style={{ color: "red" }}>{formErrors.startDate}</label>}
                                </div>
                            </Col>
                            <Col xxl={4} sm={6} className="start-estimated-date">
                                <div className="users-form-info date-picker enquery-datepicker mt-3 mx-0">
                                    <div className="users-label position-relative">
                                        <label>Estimated Delivery</label>
                                        <ReactDatePicker id='Enquirydate' dateFormat="dd/MM/yyyy" minDate={user.startDate} className='datepicker-input' selected={user.estimatedDelivery} placeholderText="Select Date" onChange={(e) => { setUser({ ...user, estimatedDelivery: e }); setFormErrors({ ...formErrors, estimatedDelivery: '' }) }} autoComplete='off' disabled={userRole !== "Admin" && (user.status !== "Approved")} />
                                        <img src={Datepickericon} onClick={() => openCalender('Enquirydate')} alt="" className='datepickericon' />
                                    </div>
                                    {formErrors?.estimatedDelivery && <label style={{ color: "red" }}>{formErrors.estimatedDelivery}</label>}
                                </div>
                            </Col>
                            <Col xxl={4} sm={6}>
                                <div className="users-form-info finalquotation mt-3">
                                    <div className="multi-select customer-width">
                                        <label>Contractor</label>
                                        <div className='d-flex' style={{ width: "100%" }}>
                                            <Select
                                                placeholder="Select Contractor"
                                                value={user.contractor}
                                                onChange={(e) => {
                                                    setUser({ ...user, contractor: e })
                                                    if (e) {
                                                        setFormErrors({ ...formErrors, contractor: '' })
                                                    }
                                                }}
                                                options={contractorOption}
                                                isMulti={true}
                                                style={{ width: "100%" }}
                                            />
                                            {(userRole === UserRoles.Admin || user.status === "Approved") && <button className="btn btn-outline-secondary contractor-btn" type="button" style={{ height: "38px", zIndex: "1" }}
                                                onClick={() => {
                                                    setContactorTog(true);
                                                    dispatch({
                                                        type: FILE_UPLOAD,
                                                        payload: []
                                                    })
                                                }}>Add</button>}
                                        </div>
                                    </div>
                                    {formErrors?.contractor && <span style={{ color: "red" }}>{formErrors.contractor}</span>}
                                </div>
                            </Col>
                            <Col xxl={4} sm={6}>
                                <div className="users-form-info finalquotation mt-3">
                                    <div className="multi-select customer-width">
                                        <label>Final Quotation</label>
                                        <div className='d-flex'>
                                            <input
                                                className='form-control'
                                                placeholder="Select Final Quotation"
                                                // value={selectValue(user.finalQuatation, "finalQuatation")}
                                                // value={user.finalQuatation ? user.finalQuatation.split('/')?.length > 1 ? user.finalQuatation.split('/')[1] : getQuotationById?.data?.quotationName : ''}
                                                value={user.finalQuatation ? getQuotationById?.data?.quotationName: ''}
                                                onChange={(e) => handleChangeMulti(e, "finalQuatation")}
                                                // isDisabled={userRole !== UserRoles.Admin}
                                                disabled
                                            />
                                            <div>
                                                <button className="btn btn-outline-secondary contractor-btn mt-0" type="button" style={{ height: "38px", marginTop: "5px", zIndex: "1" }} onClick={() => { onDownload(user.finalQuatation) }}>
                                                    <img src={downloadWhite} alt="View" width="20" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    {formErrors?.finalQuatation && <span style={{ color: "red" }}>{formErrors.finalQuatation}</span>}
                                </div>
                            </Col>
                            <Col xxl={4} sm={6}>
                                <div className="users-form-info add-remark-modal mx-0 mt-3">
                                    <div className="multi-select customer-width">
                                        <label>Customer</label>
                                        <Select
                                            placeholder="Select Customer"
                                            className='w-100'
                                            value={selectValue(user.customer, "customer")}
                                            onChange={(e) => { setUser({ ...user, customer: e.value }); setFormErrors({ ...formErrors, customer: '' }) }}
                                            options={customerOption}
                                            isDisabled={userRole !== "Admin"}
                                        />
                                    </div>
                                    {formErrors?.customer && <span style={{ color: "red" }}>{formErrors.customer}</span>}
                                </div>
                            </Col>
                            <Col xxl={4} sm={6}>
                                <div className="users-form-info add-remark-modal mx-0 mt-3">
                                    <div className="multi-select customer-width">
                                        <label>Project Manager</label>
                                        <Select
                                            placeholder="Select project role"
                                            className='w-100'
                                            value={selectValue(user.projectManager, "projectManager")}
                                            onChange={(e) => handleChangeMulti(e, "projectManager")}
                                            options={userOption}
                                        />
                                    </div>
                                    {formErrors?.projectManager && <label style={{ color: "red" }}>{formErrors.projectManager}</label>}
                                </div>
                            </Col>
                            {user.status && user.status !== "Approved" &&
                                <Col xxl={4} sm={6}>

                                    <div className="mt-3">
                                        <div className="users-form-info finalquotation">
                                            <div className="users-label">
                                                <label>Project Status</label>
                                                <div className="position-relative select-icon" style={{ width: "max-content" }} >
                                                    <img src={Arrowimg} className={`${statusId === `status` && "rotateArrow"}`} alt="" />
                                                    <select className="form-control" value={user.status} onBlur={() => Blur()} onClick={() => Click(`status`)} onChange={(e) => { setUser({ ...user, status: e.target.value }); setFormErrors({ ...formErrors, status: '' }) }} name="status">
                                                        <option value="">Select project status</option>
                                                        <option value={AllStatus.Pending}>Pending For Admin Approval</option>
                                                        <option value={AllStatus.InProgress}>{AllStatus.InProgress}</option>
                                                        <option value={AllStatus.OnHold}>{AllStatus.OnHold}</option>
                                                        <option value={AllStatus.Done}>{AllStatus.Done}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            {formErrors?.status && <label style={{ color: "red" }}>{formErrors.status}</label>}
                                        </div>
                                    </div>
                                </Col>
                            }

                            <Col xxl={4} sm={6}>
                                <div className="users-form-info mt-3">
                                    <div className="">
                                        <label style={{ fontWeight: "600" }}>Work Order File</label>
                                        <div className="upload-file mt-1 small-file-input w-100" onClick={() => UploadClick()}>
                                            <label className='upload-file-lable' >Upload here</label>
                                            <input
                                                type="file"
                                                placeholder="Enter work order"
                                                className="form-control d-none"
                                                id='workOrder'
                                                onChange={(e) => { setWorkOrderFile(e.target.files[0]); setFileName('workOrderFile') }}
                                                name="workOrder"
                                            />
                                        </div>
                                        {user.workOrder.length > 0 ?
                                            user.workOrder.map((ele, i) => ele && <label style={{ color: "green", display: "flex", marginTop: '10px' }}>
                                                <img src={Viewicon} alt="View" width="20px" height="20px" style={{ marginRight: "5px", cursor: "pointer" }} onClick={() => fileOpen(ele)} />
                                                <img src={removeImg} alt="Remove" width="20px" height="20px" style={{ marginRight: "5px", cursor: "pointer" }} onClick={() => fileRemove(ele, i, 'removeFileWorkOrder')} />
                                                Uploaded File : {ele.split('/')[1] ? ele.split('/')[1] : ele}
                                            </label>)
                                            :
                                            formErrors?.workOrder ? <label style={{ color: "red" }}>{formErrors.workOrder}</label> : ''}
                                    </div>
                                </div>
                            </Col>

                        </Row>
                    </div>
                    <div className="users-form mt-4 steps-user-for">
                        <div className=''>
                            <h3 className='Milestores-title'>Steps</h3>
                        </div>
                        <div className='over-flow-scroll'>
                            {milestone && milestone.map((data, i) => (
                                <>
                                    <div className='milestores-main mt-1' key={i}>
                                        <Row>
                                            <Col xs={1} style={{ width: '30px' }}>
                                                <h5 className='mt-4 pt-3'>{i + 1}</h5>
                                            </Col>
                                            <Col sm={11} xs={10}>
                                                <Row>
                                                    <Col xxl={2} md={6} sm={6}>
                                                        <div className="users-form-info mt-3">
                                                            <div className="users-label">
                                                                <label>Name</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="exampleInputPassword1"
                                                                    placeholder="Enter name"
                                                                    value={data.step}
                                                                    onChange={(e) => { handleChangeData(e, i, 'step') }}
                                                                    name={`step${i}`}
                                                                    autoComplete="off"
                                                                    disabled={i < stepLength ? userRole !== "Admin" && user.status !== "Approved" : false}
                                                                />
                                                                {formErrors && formErrors[`step${i + 1}`] && <span style={{ color: "red" }}>{formErrors[`step${i + 1}`]}</span>}
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={3} md={6} sm={6}>
                                                        <div className="mt-3 sm-picker">
                                                            <div className="users-form-info date-picker enquery-datepicker">
                                                                <div className="users-label">
                                                                    <label>Estimated Delivery</label>
                                                                    <input
                                                                        type='date'
                                                                        id={`Estimateddelivery-${i}`}
                                                                        value={data?.estimatedDelivery && GetDateValues(data?.estimatedDelivery)}
                                                                        style={{ padding: "0px 10px" }}
                                                                        onChange={(e) => handleChangeData(e?.target?.value, i, 'estimatedDelivery')}
                                                                        disabled={i < stepLength ? userRole !== "Admin" && (user.status !== "Approved") : false}
                                                                    />
                                                                </div>
                                                                <img src={Datepickericon} onClick={() => openCalender(`Estimateddelivery-${i}`)} alt="" className='datepickericon' />
                                                                {formErrors && formErrors[`estimatedDelivery${i + 1}`] && <label style={{ color: "red" }}>{formErrors[`estimatedDelivery${i + 1}`]}</label>}
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={3} md={6} sm={6}>
                                                        <div className="mt-3 sm-picker">
                                                            <div className="users-form-info date-picker enquery-datepicker">
                                                                <div className="users-label">
                                                                    <label>Delivered On</label>
                                                                    <input
                                                                        type='date'
                                                                        id={`deliveredOn-${i}`}
                                                                        value={data?.deliveredOn && GetDateValues(data?.deliveredOn)}
                                                                        style={{ padding: "0px 10px" }}
                                                                        onChange={(e) => handleChangeData(e?.target?.value, i, 'deliveredOn')}
                                                                        disabled={i < stepLength ? userRole !== "Admin" && userData?.data?.projectDetail[i]?.status === AllStatus.Done : false}
                                                                    />
                                                                </div>
                                                                <img src={Datepickericon} onClick={() => openCalender(`deliveredOn-${i}`)} alt="" className='datepickericon' />
                                                                {formErrors && formErrors[`deliveredOn${i + 1}`] && <label style={{ color: "red" }}>{formErrors[`deliveredOn${i + 1}`]}</label>}
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} md={6} sm={6}>
                                                        <div className="users-form-info d-flex align-items-center mt-3">
                                                            <div className="users-label w-100">
                                                                <label>Description</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Enter description"
                                                                    value={data.description}
                                                                    onChange={(e) => handleChangeData(e, i, 'description')}
                                                                    autoComplete="off"
                                                                    disabled={i < stepLength ? userRole !== "Admin" && (user.status !== "Approved") : false}
                                                                />
                                                                {formErrors && formErrors[`description${i + 1}`] && <span style={{ color: "red" }}>{formErrors[`description${i + 1}`]}</span>}
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={1} md={2} sm={3} xs={6}>
                                                        <div className="mt-3 sm-picker prodat-status-switch">
                                                            <div className="users-form-info date-picker status-select">
                                                                <div className="users-label">
                                                                    <label>Status</label>
                                                                    {/* <div
                                                                    className="position-relative select-icon"
                                                                >
                                                                    <img src={Arrowimg} className={`${statusId === `status${i}` && "rotateArrow"}`} alt="" />
                                                                    <select className="form-control" value={data.status} onBlur={() => Blur()} onClick={() => Click(`status${i}`)} onChange={(e) => handleChangeData(e, i, 'status')} name="status" disabled={i < stepLength ? userRole !== "Admin" && userData?.data?.projectDetail[i]?.status === AllStatus.Done : false}>
                                                                        <option value="Select Status">Select Status</option>
                                                                        <option value={AllStatus.Pending}>Pending For Admin Approval</option>
                                                                        <option value={AllStatus.InProgress}>{AllStatus.InProgress}</option>
                                                                        <option value={AllStatus.OnHold}>{AllStatus.OnHold}</option>
                                                                        <option value={AllStatus.Done}>{AllStatus.Done}</option>
                                                                    </select>
                                                                </div> */}
                                                                    <Form className='status-switch mt-2'>
                                                                        <Form.Check
                                                                            type="switch"
                                                                            id="custom-switch"
                                                                            checked={data.status === "Done" ? true : false}
                                                                            onChange={(e) => handleChangeData(e, i, 'status')}
                                                                            style={{ width: '100px', height: '42px', }}
                                                                        // label="Check this switch"
                                                                        />
                                                                    </Form>
                                                                </div>
                                                                {/* {formErrors && formErrors[`status${i + 1}`] && <label style={{ color: "red" }}>{formErrors[`status${i + 1}`]}</label>} */}
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={1} md={2} sm={3} xs={6} style={{ width: 'fit-content', lg: { marginLeft: '16px' } }}>
                                                        <div className="sm-picker ms-xl-0 ms-3 mt-5 mt-lg-5">
                                                            <div className="mt-3 users-form-info date-picker">
                                                                <div className="">
                                                                    {/* {userData.status === "Approved" && <span> */}
                                                                    {(i === milestone.length - 1) && <img src={Plusicon} onClick={addData} width="30" style={{ cursor: "pointer" }} alt='' />}
                                                                    {milestone.length > 1 && <img src={Minusicon} onClick={() => Remove(i)} width="35" style={{ cursor: "pointer" }} alt='' />}
                                                                    {/* </span>} */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </>
                            )
                            )}
                        </div>
                        <div className='row'>
                            {/* <div className='col-1'> </div> */}
                            <div className="col-md-4 col-sm-12 mt-3 col-lg-5">
                                <div className="users-form-info mt-4 mt-md-0">
                                    <div className="upload-file " onClick={focusInput}>
                                        <label className='upload-file-lable' >Add Measurment Sheet </label>
                                        <input
                                            type="file"
                                            className="form-control d-none"
                                            id='file'
                                            onChange={(e) => { setFile(e.target.files[0]); setFileName("measurementFile") }}
                                            ref={inputElement}
                                        />
                                    </div>
                                    {user.file.length > 0 ?
                                        user.file.map((ele, i) => ele && <label style={{ color: "green", marginTop: "10px" }} key={i}>
                                            <img src={Viewicon} alt="View" width="20" style={{ marginRight: "5px" }} onClick={() => fileOpen(ele)} />
                                            <img src={removeImg} alt="Remove" width="20" style={{ marginRight: "5px" }} onClick={() => fileRemove(ele, i, 'removeFile')} />
                                            Uploaded File : {ele.split('/')[1]}
                                        </label>)
                                        :
                                        formErrors?.file && <label style={{ color: "red" }}>{formErrors.file}</label>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {purchaseReqTog && <AddPurchaseRequest show={purchaseReqTog} onHide={() => hidePR()} addPRId={purchaseRequestId} currentProject={{ projectName: user?.partyName }} />}
            {contactorTog && <AddContractor show={contactorTog} onHide={() => HideContract()} contractorId={''} />}
            {assetsTog && <AssetsProject show={assetsTog} onHide={() => HideAssets()} />}
            {finalMaterialTog && <FinalMaterial show={finalMaterialTog} onHide={() => HideFinalMaterial()} status={user.status} isFinalMaterialConfirmed={userData?.data?.isFinalMaterialConfirmed} />}
            {viewFinalQuotation && <ViewFinalQuotation show={viewFinalQuotation} onHide={() => setViewFinalQuotation(false)} select={selectValue(user.finalQuatation, "finalQuatation")} />}
            {isProductWiseData && <ProductWisePr show={isProductWiseData} onHide={() => setIsProductWiseData(false)} projectId={projectId} />}
            {
                confirmTog &&
                <SweetAlert
                    success
                    showCancel
                    confirmBtnText="Yes"
                    confirmBtnBsStyle="success"
                    title="Are you sure?"
                    onConfirm={() => {
                        confirmAndSave()
                    }}
                    onCancel={() => { setConfirmTog(false) }}
                    focusCancelBtn
                >
                    Are you sure confirm and save
                </SweetAlert>
            }
        </>
    )
}

export default ProjectEdit
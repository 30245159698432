import React, { useContext, useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import Datepickericon from "../../assets/image/date-picker-icon.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Plusicon from "../../assets/image/plus.png";
import Minusicon from "../../assets/image/minus.png";
import { getProjectByCustomerAction } from "../../redux/actions/projectAction";
import { getCustomerDropDownAction } from "../../redux/actions/customerAction";
import { getProductDropDownAction } from "../../redux/actions/productAction";
import { ADD_SALE, EDIT_SALE, GET_SALE_BY_ID } from "../../redux/type"
import { addSaleAction, editSaleAction, getSaleByIdAction } from "../../redux/actions/salesAction";
import { Col, Row } from "react-bootstrap";
import Select from 'react-select'
import { UserRoles } from "../../helper/Constant";
import { RoleContext } from "../../helper/RoleProvider";
import img from "../../assets/image/select.png";

const AddSale = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search)
    const saleId = queryParams.get("saleId")
    const userRole = useContext(RoleContext);
    const toastSuccess = () => toast.success("Sales added successfully");
    const toastUpdate = () => toast.success("Sales update successfully");
    const toastInvoiceAlreadyExists = () => toast.warning("Invoice number already exists.");
    const [formErrors, setFormErrors] = useState({});
    const [saleForm, setSaleForm] = useState({
        invoiceDate: "",
        customer: "",
        project: "",
        invoiceNo: "",
        narration: "",
        termAndConditions: "",
        productAmount: 0,
        transpoter: "",
        purchaseRequest: "",
        refby: "",
        totalAmount: 0,
    });
    const [editId, setEditId] = useState()
    const [customerOption, setCustomerOption] = useState([])
    const [projectOption, setProjectOption] = useState([])
    const [productOption, setProductOption] = useState([])
    const [rotateArrow, setRotateArrow] = useState('');
    const customerData = useSelector((state) => state.get_Customer_Drop_Down.getCustomerDropDown.data)
    const projectData = useSelector((state) => state.get_Project_By_Customer.getProjectByCustomer.data)
    const productData = useSelector((state) => state.get_Product_Drop_Down.getProductDropDown.data)
    const saleData = useSelector((state) => state.get_Sale_By_Id.getSaleById.data)
    const addSaleData = useSelector((state) => state.add_Sale.addSale)
    const editSaleData = useSelector((state) => state.edit_Sale.editSale)

    const [purchaseItem, setPurchaseItem] = useState([
        {
            productCode: "",
            productName: "",
            description: "",
            quantity: 0,
            rate: 0,
            amount: 0,
            showPrice: 0,
            showQty: 0,
            gst: 18,
            discount: 0,
            finalAmount: 0
        },
    ]);

    useEffect(() => {
        dispatch(getCustomerDropDownAction())
        dispatch(getProductDropDownAction())
    }, [])

    useEffect(() => {
        if (saleId) {
            setEditId(saleId)
            dispatch(getSaleByIdAction(saleId))
        }
    }, [saleId])

    useEffect(() => {
        if (customerData) {
            let temp = customerData.sort((a, b) => a.accountName.localeCompare(b.accountName, undefined, { case: 'upper' }))
            setCustomerOption(temp.map((ele) => {
                return {
                    value: ele._id,
                    label: ele.accountName
                }
            }))
        }
    }, [customerData])

    useEffect(() => {
        if (projectData) {
            let temp = projectData.sort((a, b) => a.partyName.localeCompare(b.partyName, undefined, { case: 'upper' }))
            setProjectOption([
                {
                    value: " ",
                    label: "Trading"
                }, ...temp.map((ele) => {
                    return {
                        value: ele._id,
                        label: ele.partyName
                    }
                })
            ])
            if (!temp.length) {
                setSaleForm({ ...saleForm, project: " " })
            }
        }
    }, [projectData])

    useEffect(() => {
        if (productData) {
            let temp = productData.sort((a, b) => a.productName.localeCompare(b.productName, undefined, { case: 'upper' }))
            setProductOption(temp.map((ele) => {
                return {
                    value: ele.productCode,
                    label: ele.productCode ? `${ele.productName}` : ele.productName
                }
            }))
        }
    }, [productData])

    useEffect(() => {
        if (saleData && saleId && productData) {
            setSaleForm({
                invoiceDate: new Date(saleData.invoiceDate),
                customer: saleData.customer,
                project: saleData.project,
                invoiceNo: saleData.invoiceNo,
                narration: saleData.narration,
                termAndConditions: saleData.termAndConditions,
                productAmount: saleData.productAmount,
                transpoter: saleData.transpoter,
                purchaseRequest: saleData.purchaseRequest,
                refby: saleData.refby,
                totalAmount: saleData.totalAmount,
            })
            setPurchaseItem(saleData.purchaseItems.map((item) => {
                return {
                    productCode: item.productCode,
                    productName: item.productName,
                    description: item.description,
                    quantity: item.quantity,
                    rate: item.rate,
                    amount: item.amount,
                    showPrice: productData?.find((items) => items.productCode === item.productCode)?.retailSalePrice,
                    showQty: productData?.find((items) => items.productCode === item.productCode)?.stock,
                    gst: item?.gst ?? 0,
                    discount: item?.discount ?? 0,
                    finalAmount: item?.finalAmount ?? item?.amount,
                }
            }))
        }
    }, [saleData, productData])

    useEffect(() => {
        if (saleForm.customer) {
            dispatch(getProjectByCustomerAction(saleForm.customer))
        }
    }, [saleForm.customer])

    const handleChange = (e, name) => {
        setFormErrors({ ...formErrors, [name]: '' })
        if (name === "customer") {
            setSaleForm({
                ...saleForm,
                [name]: e.value,
                project: ''
            });
        }
        else if (name === "project") {
            setSaleForm({ ...saleForm, [name]: e.value });
        }
        else {
            setSaleForm({
                ...saleForm,
                [name]: e.target.value,
            });
        }
    };

    const Submit = () => {
        if (validation()) {
            return
        }
        let body = {
            ...saleForm,
            // project: productOption.find(item => item.lable === saleForm.project)?.value ?? '',
            purchaseItems: purchaseItem.map((item) => {
                return {
                    productCode: item.productCode,
                    productName: productData.find((el) => el.productCode === item.productCode).productName,
                    // productName: item.productName,   
                    description: item.description,
                    quantity: item.quantity,
                    rate: item.rate,
                    amount: Number(item.quantity ?? 0) * Number(item.rate ?? 0),
                    gst: item.gst,
                    discount: item.discount,
                    finalAmount: item.finalAmount,
                }
            })
        }
        dispatch(addSaleAction(body))
    };

    useEffect(() => {
        if (addSaleData && addSaleData.data) {
            if (addSaleData.message === "Sell created successfully.") {
                dispatch({
                    type: ADD_SALE,
                    payload: []
                })
                navigate('/sale')
                toastSuccess()
            }
        }
    }, [addSaleData])


    const Edit = () => {
        if (validation()) {
            return
        }
        let body = {
            ...saleForm,
            purchaseItems: purchaseItem.map((item) => {
                return {
                    productCode: item.productCode,
                    productName: productData.find((el) => el.productCode === item.productCode).productName,
                    // productName: item.productName,
                    description: item.description,
                    quantity: item.quantity,
                    rate: item.rate,
                    amount: Number(item.quantity ?? 0) * Number(item.rate ?? 0),
                    gst: item.gst,
                    discount: item.discount,
                    finalAmount: item.finalAmount,
                }
            }),
            id: editId
        }
        dispatch(editSaleAction(body))
    };

    useEffect(() => {
        if (editSaleData && editSaleData.data && editSaleData.data.status === 200) {
            dispatch({
                type: EDIT_SALE,
                payload: []
            })
            if (editSaleData.data.message === "Invoice Number Already Exists.") {
                toastInvoiceAlreadyExists(true)
                return
            } else if (editSaleData.data.message === "Sell updated successfully.") {
                navigate('/sale')
                toastUpdate(true)
            }
        }
    }, [editSaleData])

    const addData = () => {
        let val = [...purchaseItem];
        val.push({
            productCode: "",
            productName: "",
            description: "",
            quantity: 0,
            rate: 0,
            amount: 0,
            showPrice: 0,
            showQty: 0,
            gst: 18,
            discount: 0,
            finalAmount: 0
        });
        setPurchaseItem(val);
    };

    const Remove = (i) => {
        if (purchaseItem.length > 1) {
            let val = [...purchaseItem];
            val.splice(i, 1);
            setPurchaseItem(val);
        }
    };

    const Blur = () => {
        setRotateArrow('')
    }

    const Click = (name) => {
        if (rotateArrow === name) {
            setRotateArrow('')
        } else {
            setRotateArrow(name)
        }
    }

    const decimalNumberWithMaxVal = (value) => {
        const regexwithdecimal = /^(?:\d*\.\d{0,2}|\d+)$/;
        if (regexwithdecimal.test(value) && +value <= 100) {
            return true
        }
        return false;
    }

    const handleChangeData = (e, i, name) => {
        setFormErrors({ ...formErrors, [`${name}${i + 1}`]: '' })
        let val = [...purchaseItem];
        let regex = /^[0-9\b]+$/;
        let regexwithdecimal = /^(?:\d*\.\d{0,2}|\d+)$/;
        if (name === 'productCode') {
            val[i].productCode = e.value;
            val[i].productName = e.label;
            val[i].showPrice = productData.find((item) => item.productCode === e.value)?.retailSalePrice ?? 0
            val[i].showQty = productData.find((item) => item.productCode === e.value)?.stock ?? 0
            setPurchaseItem(val);
        } else if (name === "description") {
            val[i].description = e.target.value;
            setPurchaseItem(val);
        } else if (name === "rate") {
            if (e.target.value === "" || regexwithdecimal.test(e.target.value)) {
                const currentRate = e.target.value ? Number(e.target.value) : 0
                let tempQty = val[i].quantity ? Number(val[i].quantity) : 0
                let tempGST = val[i].gst ? Number(val[i].gst) : 0
                let tempDic = val[i].discount ? Number(val[i].discount) : 0
                let amount = tempQty * currentRate
                let finalAmount = amount - (amount * tempDic / 100);
                finalAmount = finalAmount + (finalAmount * tempGST / 100);
                val[i].rate = currentRate;
                val[i].amount = amount;
                val[i].finalAmount = finalAmount;
                setPurchaseItem(val);
            }
        } else if (name === "quantity") {
            if (e.target.value === "" || regexwithdecimal.test(e.target.value)) {
                const currentQty = e.target.value ? Number(e.target.value) : 0
                let tempRate = val[i].rate ? Number(val[i].rate) : 0
                let tempGST = val[i].gst ? Number(val[i].gst) : 0
                let tempDic = val[i].discount ? Number(val[i].discount) : 0
                let amount = currentQty * tempRate
                let finalAmount = amount - (amount * tempDic / 100);
                finalAmount = finalAmount + (finalAmount * tempGST / 100);
                val[i].quantity = currentQty;
                val[i].amount = amount;
                val[i].finalAmount = finalAmount;
                setPurchaseItem(val);
            }
        } else if (name === "gst") {
            if (e.target.value === "" || decimalNumberWithMaxVal(e.target.value)) {
                const currentGST = e.target.value ? Number(e.target.value) : 0
                let tempQty = val[i].quantity ? Number(val[i].quantity) : 0
                let tempRate = val[i].rate ? Number(val[i].rate) : 0
                let tempDic = val[i].discount ? Number(val[i].discount) : 0
                let finalAmount = tempQty * tempRate
                finalAmount = finalAmount - (finalAmount * tempDic / 100);
                finalAmount = finalAmount + (finalAmount * currentGST / 100);
                val[i].gst = currentGST;
                val[i].finalAmount = finalAmount;
                setPurchaseItem(val);
            }
        } else if (name === "discount") {
            if (e.target.value === "" || decimalNumberWithMaxVal(e.target.value)) {
                const currentDisc = e.target.value ? Number(e.target.value) : 0
                let tempQty = val[i].quantity ? Number(val[i].quantity) : 0
                let tempRate = val[i].rate ? Number(val[i].rate) : 0
                let tempGST = val[i].gst ? Number(val[i].gst) : 0
                let finalAmount = tempQty * tempRate
                val[i].discount = currentDisc;
                finalAmount = finalAmount - (finalAmount * currentDisc / 100);
                finalAmount = finalAmount + (finalAmount * tempGST / 100);
                val[i].finalAmount = finalAmount;
                setPurchaseItem(val);
            }
        }
    };

    useEffect(() => {
        if (purchaseItem) {
            let prodAmt = 0;
            let totalAmt = 0;
            purchaseItem.forEach((item) => {
                prodAmt += item.amount;
                totalAmt += item?.finalAmount ?? item?.amount ?? 0;
            })
            setSaleForm({ ...saleForm, productAmount: prodAmt, totalAmount: totalAmt })
        }
    }, [purchaseItem])


    const validation = () => {
        let flag = false;
        const error = {};
        if (!saleForm.invoiceDate) {
            error.invoiceDate = "Please select invoice date";
            flag = true;
        }
        if (!saleForm.customer) {
            error.customer = "Please select customer";
            flag = true;
        }
        if (!saleForm.project && (projectData && projectData.length)) {
            error.project = "Please select project";
            flag = true;
        }
        // if (!saleForm.invoiceNo) {
        //     error.invoiceNo = "Please enter invoice number";
        //     flag = true;
        // }
        if (!saleForm.transpoter) {
            error.transpoter = "Please enter transpoter";
            flag = true;
        }
        if (!saleForm.purchaseRequest) {
            error.purchaseRequest = "Please enter purchase request";
            flag = true;
        }
        if (!saleForm.refby) {
            error.refby = "Please enter refby";
            flag = true;
        }
        if (purchaseItem.length) {
            console.log("🚀 ~ validation ~ purchaseItem:", purchaseItem)
            purchaseItem.map((item, i) => {
                if (item.productCode === "") {
                    error[`productCode${i + 1}`] = `Please select product name ${i + 1}`
                    flag = true;
                }
                if (item.quantity == 0) {
                    error[`quantity${i + 1}`] = `Please enter quantity ${i + 1}`
                    flag = true;
                }
                if (Number(item.quantity) > Number(item.showQty)) {
                    error[`quantity${i + 1}`] = `Enter quantity is greater than on hand quantity ${i + 1}`
                    flag = true;
                }
                if (item.rate == 0) {
                    error[`rate${i + 1}`] = `Please enter rate ${i + 1}`
                    flag = true;
                }
            })
        }
        setFormErrors(error);
        return flag;
    };

    const openCalender = (id) => {
        document.getElementById(id).click();
    };

    useEffect(() => {
        return () => {
            dispatch({
                type: GET_SALE_BY_ID,
                payload: []
            })
        }
    }, [])

    const selectValue = (value, name) => {        
        if (name === "customer") {
            return customerOption.find((ele) => ele.value === value)
        } else if (name === "project") {
            return projectOption.find((ele) => ele.value === value)
        } else if (name === "product") {
            return productOption.find((ele) => ele.value === value)
        }
    }

    return (
        <>
            <div className="addEnquiry">
                <div className="position-relative p-4">
                    <div className="mb-4 set-page-heade">
                        <div className="d-flex flex-column">
                            <h2 className='mb-0'>{editId ? "Edit Sale" : "Add Sale"}</h2>
                        </div>
                        <div>
                            {editId ? <button className="adduser-btn" onClick={() => Edit()}>Edit</button> : <button className="adduser-btn" onClick={() => Submit()}>Save</button>}
                            <button className="cancel-button ms-3" onClick={() => navigate("/sale")}>Cancel</button>
                        </div>
                    </div>
                    <div className="users-form">
                        <Row>
                            <Col className="mt-3" lg={3} md={4} sm={6}>
                                <div className="users-form-info date-picker enquery-datepicker">
                                    <div className="users-label">
                                        <label>Invoice Date</label>
                                        <ReactDatePicker
                                            id="EnquiryDatePicker"
                                            dateFormat="dd/MM/yyyy"
                                            selected={saleForm.invoiceDate}
                                            placeholderText="Select invoice date"
                                            onChange={(e) => { setSaleForm({ ...saleForm, invoiceDate: e }); setFormErrors({ ...formErrors, invoiceDate: '' }) }}
                                            autoComplete="off"
                                        />
                                    </div>
                                    {formErrors?.invoiceDate && (<label style={{ color: "red" }}>{formErrors.invoiceDate}</label>)}
                                    <img src={Datepickericon} onClick={() => openCalender("EnquiryDatePicker")} alt="" className="datepickericon" />
                                </div>
                            </Col>
                            <Col className="mt-3" lg={3} md={4} sm={6}>
                                <div className="users-form-info add-remark-modal mx-0">
                                    <div className="multi-select customer-width">
                                        <label>Customer</label>
                                        <Select
                                            placeholder="Select Customer"
                                            value={saleForm.customer && selectValue(saleForm.customer, "customer")}
                                            onChange={(e) => handleChange(e, 'customer')}
                                            options={customerOption}
                                            className="w-100"
                                        />
                                    </div>
                                    {formErrors?.customer && <span style={{ color: "red" }}>{formErrors.customer}</span>}
                                </div>
                            </Col>
                            <Col className="mt-3" lg={3} md={4} sm={6}>
                                <div className="users-form-info add-remark-modal mx-0">
                                    <div className="multi-select customer-width">
                                        <label>Project Name</label>
                                        <Select
                                            placeholder="Select Project"
                                            value={saleForm.project && selectValue(saleForm.project, "project")}
                                            onChange={(e) => handleChange(e, 'project')}
                                            options={projectOption}
                                            className="w-100"
                                        />
                                    </div>
                                    {formErrors?.project && <span style={{ color: "red" }}>{formErrors.project}</span>}
                                </div>
                            </Col>
                            {saleId && <Col className="mt-3" lg={3} md={4} sm={6}>
                                <div className="users-form-info ">
                                    <div className="users-label">
                                        <label>Invoice Number</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter invoice number"
                                            value={saleForm.invoiceNo}
                                            onChange={(e) => handleChange(e, 'invoiceNo')}
                                            autoComplete="off"
                                            disabled={userRole !== UserRoles.Admin}
                                        />
                                    </div>
                                    {/* {formErrors?.invoiceNo && (<label style={{ color: "red" }}>{formErrors.invoiceNo}</label>)} */}
                                </div>
                            </Col>}
                            <Col className="mt-3" lg={3} md={4} sm={6}>
                                <div className="users-form-info ">
                                    <div className="users-label">
                                        <label>Transpoter</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter transpoter"
                                            value={saleForm.transpoter}
                                            onChange={(e) => handleChange(e, "transpoter")}
                                            autoComplete="off"
                                        />
                                    </div>
                                    {formErrors?.transpoter && (<label style={{ color: "red" }}>{formErrors.transpoter}</label>)}
                                </div>
                            </Col>
                            <Col className="mt-3" lg={3} md={4} sm={6}>
                                <div className="users-form-info ">
                                    <div className="users-label">
                                        <label>Purchase Request</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter purchase request"
                                            value={saleForm.purchaseRequest}
                                            onChange={(e) => handleChange(e, "purchaseRequest")}
                                            autoComplete="off"
                                        />
                                    </div>
                                    {formErrors?.purchaseRequest && (<label style={{ color: "red" }}>{formErrors.purchaseRequest}</label>)}
                                </div>
                            </Col>
                            <Col className="mt-3" lg={3} md={4} sm={6}>
                                <div className="users-form-info ">
                                    <div className="users-label">
                                        <label>Reference By</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter reference by"
                                            value={saleForm.refby}
                                            onChange={(e) => handleChange(e, "refby")}
                                            autoComplete="off"
                                        />
                                    </div>
                                    {formErrors?.refby && (<label style={{ color: "red" }}>{formErrors.refby}</label>)}</div>
                            </Col>
                            <Col className="mt-3" lg={3} md={4} sm={6}>
                                <div className="users-form-info">
                                    <div className="users-label">
                                        <label>Narration</label>
                                        <textarea
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter narration"
                                            value={saleForm.narration}
                                            onChange={(e) => handleChange(e, "narration")}
                                            autoComplete="off"
                                            rows={1}
                                            style={{ height: 'unset', width: '100% !impotant' }}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col className="mt-3" lg={3} md={4} sm={6}>
                                <div className="users-form-info">
                                    <div className="users-label">
                                        <label>Term And Conditions</label>
                                        <textarea
                                            type="text"
                                            className="form-control mt-1"
                                            placeholder="Enter term and conditions"
                                            value={saleForm.termAndConditions}
                                            onChange={(e) => handleChange(e, "termAndConditions")}
                                            autoComplete="off"
                                            rows={1}
                                            style={{ height: 'unset' }}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col className="mt-3" lg={3} md={4} sm={6}>
                                <div className="users-form-info date-picker">
                                    <div className="users-label">
                                        <label>Product Amount</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter product amount"
                                            value={saleForm.productAmount}
                                            onChange={(e) => handleChange(e, "productAmount")}
                                            autoComplete="off"
                                            disabled
                                        />
                                    </div>
                                    {formErrors?.productAmount && (<label style={{ color: "red" }}>{formErrors.productAmount}</label>)}
                                </div>
                            </Col>
                            <Col className="mt-3" lg={3} md={4} sm={6}>
                                <div className="users-form-info">
                                    <div className="users-label">
                                        <label>Total Amount</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter total amount"
                                            value={saleForm.totalAmount?.toFixed(2)}
                                            name="quotationNumber"
                                            onChange={(e) => handleChange(e, "totalAmount")}
                                            autoComplete="off"
                                            disabled
                                        />
                                    </div>
                                    {formErrors?.totalAmount && (<label style={{ color: "red" }}>{formErrors.totalAmount}</label>)}
                                </div>
                            </Col>
                        </Row>
                        <div className="row mt-5">
                            <div className="users-label users-form-info">
                                <h3 className='Milestores-title mb-0'>Product Details</h3>
                            </div>
                        </div>
                        <div>
                            {purchaseItem && purchaseItem.map((data, i) => (
                                <div key={i}>
                                    <div className="d-flex align-items-center gap-3">
                                        <h5>{i + 1}</h5>
                                        <div
                                            style={{
                                                flex: 1,
                                                ...(i !== purchaseItem.length - 1 && { paddingRight: "30px" })
                                            }}
                                        >
                                            <Row>
                                                <Col xxl={5} xl={5} lg={4} md={12}>
                                                    <div className="mt-3 small-input">
                                                        <div className="users-form-info ms-0 me-0 w-100">
                                                            <div className="multi-select">
                                                                <label>Product Name</label>
                                                                <Select
                                                                    cacheOptions
                                                                    placeholder="Select Product"
                                                                    onBlur={() => Blur()}
                                                                    value={productOption.find(item => item.value === data.productCode)}
                                                                    options={productOption}
                                                                    onChange={(e) => {
                                                                        const option = productOption?.find((item) => item.value === e.value);
                                                                        handleChangeData(option, i, 'productCode');
                                                                    }}
                                                                    style={{ marginTop: "unset", paddingRight: 30 }}
                                                                // maxMenuHeight={100}
                                                                />
                                                            </div>
                                                            {formErrors && formErrors[`productCode${i + 1}`] && <label style={{ color: "red" }}>{formErrors[`productCode${i + 1}`]}</label>}
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col xxl={5} xl={5} lg={6} md={12}>
                                                    <Row>
                                                        <Col xxl={3} xl={3} lg={3} md={4}>
                                                            <div className="mt-3 small-input">
                                                                <div className="users-form-info ">
                                                                    <div className="users-label">
                                                                        <label>Quantity</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Enter product quantity"
                                                                            onChange={(e) => handleChangeData(e, i, 'quantity')}
                                                                            value={data.quantity}
                                                                        />
                                                                        <label title={`On Hand : ${data.showQty && data.showQty}`}>OH: {data.showQty && data.showQty}</label>
                                                                    </div>
                                                                    {formErrors && formErrors[`quantity${i + 1}`] && <label style={{ color: "red" }}>{formErrors[`quantity${i + 1}`]}</label>}
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col xxl={3} xl={3} lg={3} md={4}>
                                                            <div className="mt-3 small-input">
                                                                <div className="users-form-info">
                                                                    <div className="users-label">
                                                                        <label>Rate</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Enter rate"
                                                                            onChange={(e) => handleChangeData(e, i, 'rate')}
                                                                            value={data.rate}
                                                                        />
                                                                        <label>Rate: {data.showPrice && data.showPrice}</label>
                                                                    </div>
                                                                    {formErrors && formErrors[`rate${i + 1}`] && <label style={{ color: "red" }}>{formErrors[`rate${i + 1}`]}</label>}
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col xxl={3} xl={3} lg={3} md={2}>
                                                            <div className="mt-3 small-input">
                                                                <div className="users-form-info">
                                                                    <div className="users-label">
                                                                        <label>GST %</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Enter gst"
                                                                            onChange={(e) => handleChangeData(e, i, 'gst')}
                                                                            value={data.gst}
                                                                        />
                                                                    </div>
                                                                    {formErrors && formErrors[`gst${i + 1}`] && <label style={{ color: "red" }}>{formErrors[`gst${i + 1}`]}</label>}
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col xxl={3} xl={3} lg={3} md={2}>
                                                            <div className="mt-3 small-input">
                                                                <div className="users-form-info">
                                                                    <div className="users-label">
                                                                        <label>Disc %</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Enter rate"
                                                                            onChange={(e) => handleChangeData(e, i, 'discount')}
                                                                            value={data.discount}
                                                                        />
                                                                    </div>
                                                                    {formErrors && formErrors[`discount${i + 1}`] && <label style={{ color: "red" }}>{formErrors[`discount${i + 1}`]}</label>}
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col xxl={2} xl={2} lg={2} md={12}>
                                                    <div className="mt-3 small-input">
                                                        <div className="users-form-info ms-0 me-0">
                                                            <div className="users-label">
                                                                <label>Amount</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Enter amount"
                                                                    onChange={(e) => handleChangeData(e, i, 'amount')}
                                                                    value={data?.finalAmount?.toFixed(2)}
                                                                    disabled
                                                                />
                                                            </div>
                                                            {formErrors && formErrors[`amount${i + 1}`] && <label style={{ color: "red" }}>{formErrors[`amount${i + 1}`]}</label>}
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="users-form-info pt-3">
                                            {i === purchaseItem.length - 1 && (
                                                <img
                                                    src={Plusicon}
                                                    onClick={() => { addData() }}
                                                    width="30"
                                                    height="30"
                                                    style={{ cursor: "pointer" }}
                                                    alt=""
                                                />
                                            )}
                                            {purchaseItem.length > 1 && <img
                                                src={Minusicon}
                                                onClick={() => Remove(i)}
                                                // width="33"
                                                height="33"
                                                style={{ cursor: "pointer" }}
                                                alt=""
                                            />}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddSale;
